const Logout = () => {
    
    const loc = document.location;
    const ls = localStorage;
    // clear the tokens
    if (localStorage.getItem("xcldata")!=null) {
        // fill the thing and JSON it
        const rem = ls.getItem("rememberme");
        const un = ls.getItem("xclloginusername");
        const pw = ls.getItem("xclloginpassword");

        ls.clear();

        ls.setItem("rememberme", rem );
        ls.setItem("xclloginusername", un );
        ls.setItem("xclloginpassword", pw );
        ls.setItem("editLinkID","");
        ls.setItem("editProfileID","");
        ls.setItem("xclprofiles","");
        ls.setItem("xcllinks","");
        
    }

    loc.href = "/login";

};

export default Logout;
  