const iconChevDown = require('../css/icons/icon-chev-down.png');

const cssClip = `
.listMenu {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;
    display:none;
    width:8.75rem;
    border:0.5px solid #CBD5E1;
    border-radius: 5px;
    margin:0px;
    padding:0px;
    position:absolute;
    background-color: #ffffff;
    box-shadow: 0px 10px 15px -3px #0000001A;
    overflow:hidden;
}
.listMenu.upDir {
}
.listMenu.visible {
    display:block;
}
.listmenu > button {
    width:56px;
    height:56px;
    padding:4px;
    display:block;
    margin: 0px auto;
    border:none;
    background:transparent;
    cursor: pointer;
}
.ddconntainer {
    position:absolute;
}
    
.ddbutton {
    display:block;
    margin-top: 14px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    border-radius: 10px;
    color: #0F172A;
    border: 1px solid #64748B;
    background-color: #F8FAFC;
    padding: 18px 38px 18px 18px;
    cursor: pointer;
    text-decoration: none;
    background-image: url(${iconChevDown});
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) 50%;
    min-width: 400px;
}
.ddbutton.open {
    border-color: #CBD5E1;
    background-color: #F1F5F9
}
.listMenu a {
    display: block;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 100%;
    color: #0F172A;
    padding: 15px;
}
.listMenu a.selected {
    background-color: #F9FFAA;
}
.listMenu a:hover {
    font-weight: 700;
    background-color: #F9FFAA;
}
`;

export default cssClip;