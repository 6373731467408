import ScrollToTop from '../components/ScrollToTop';
import contactUsIcon from '../img/contactus_icon.png';
import backtotopIcon from '../img/icon-backtotop.png';
import validate from '../xcl_modules/validate';
import { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";

const contactUsIconAlt = "We're here to help Icon";
const backtotopIconAlt = "Navigate back to the top of the page icon";

const TermsAndConditions = () => {

    const [navOpen] = useOutletContext();

    useEffect(() => {
        validate();
    }, []);
    
    return (
      <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
        <ScrollToTop/>
        <a name="contentstarts" className='contentStarts' href='#toc'>
            <h1>
            Terms of Use
            </h1>
        </a>
        <h4>    
        These terms outline the rules and guidelines for using our platform. Please review them carefully to understand your rights and responsibilities.
        </h4>
        <div className="helpPageTile">

          <a className='toc' href='#tableOfContents' name="tableOfContents"><h3>Table of Contents </h3></a>
          <ul className='tableOfContents'>
            <li>
                <a href="#terms">Terms of Use</a>
            </li>
            <li>
                <a href="#Privacy">Privacy</a>
            </li>
            <li>
                <a href="#ElectronicCommunications">Electronic Communications</a>
            </li>
            <li>
                <a href="#YourAccount">Your Account</a>
            </li>
            <li>
                <a href="#ChildrenUnderSixteen">Children Under 16</a>
            </li>
            <li>
                <a href="#TrialsOffersAuto-RenewalsPaidAccounts">Trials, Offers, Auto-Renewals, & Paid Accounts</a>
            </li>
            <li>
                <a href="#CancellationsandRefundPolicy">Cancellations & Refund Policy</a>
            </li>
            <li>
                <a href="#TerminationofSubscriptionandAccessRestriction">Termination of Subscription and Access Restriction</a>
            </li>
            <li>
                <a href="#LinkstoThirdPartySitesThirdPartyServices">Links to Third Party Sites & Third Party Services</a>
            </li>
            <li>
                <a href="#NoUnlawfulorProhibitedUseIntellectualProperty">No Unlawful or Prohibited Use/Intellectual Property</a>
            </li>
            <li>
                <a href="#OwnershipandPreservationofYourContent">Ownership and Preservation of Your Content</a>
            </li>
            <li>
                <a href="#InternationalUsers">International Users</a>
            </li>
            <li>
                <a href="#Indemnification">Indemnification</a>
            </li>
            <li>
                <a href="#Arbitration">Arbitration</a>
            </li>
            <li>
                <a href="#ClassActionWaiver">Class Action Waiver</a>
            </li>
            <li>
                <a href="#LiabilityDisclaimer">Liability Disclaimer</a>
            </li>
            <li>
                <a href="#GoverningLawAndJurisdictionsEntireAgreementSeverability">Governing Law And Jurisdictions; Entire Agreement; Severability</a>
            </li>
            <li>
                <a href="#ChangestoTerms">Changes to Terms</a>
            </li>
            <li>
                <a href="#Contact">Contact</a>
            </li>          
          </ul>

        <a className='toc' href="#terms" name="terms">
          <h3>Terms of Use</h3>
        </a>
        <p>Agreement between User and Learning Ninjas LLC dba Build Capable for Build Capable XCL and https://www.buildxcl.com/</p>
        <p>This Terms of Use ("Terms") applies to https://www.buildxcl.com/ ("Site"), Build Capable XCL ("Product"), and Learning Ninjas LLC dba Build 
            Capable ("Company") and governs data collection and usage. For the purposes of these Terms, unless otherwise noted, all references 
            to the Company and the Product include https://www.buildxcl.com and Build Capable XCL. The Company's Product is a learning technology 
            application. Your use of The Company's Product constitutes your agreement to all such Terms. Please read these terms carefully and keep a 
            copy of them for your reference.</p>
        <p>Build Capable XCL, the xAPI Content Link generator, can be used to create branded links to your learning content. These links can be used 
            to connect learners with content and collect learning-related data in a Learning Record Store (LRS) using the Experience API
        (xAPI).</p>

        <a className='toc' href="#privacy" name="privacy">
            <h3>Privacy</h3>
        </a>
        <p>Your use of the Company's Product is subject to Build Capable XCL's Privacy Policy. Please review our Privacy Policy, which also governs 
            the Company's Product and informs users of our data collection practices.</p>

        <a className='toc' href="#ElectronicCommunications" name="ElectronicCommunications">
        <h3>Electronic Communications</h3></a>
        <p>Visiting the Site, using the Company's Product, or sending emails to Build Capable XCL or the Company constitutes electronic communications. You 
            consent to receive electronic communications and you agree that all agreements, notices, disclosures, and other communications that we provide 
            to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>

        <a className='toc' href="#YourAccount" name="YourAccount">
        <h3>Your Account</h3></a>
        <p>If you use the Company's Product, you are responsible for maintaining the confidentiality of your account and password and for restricting 
            access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not 
            share or transfer your account or password to any other person or entity. You acknowledge that The Company is not responsible for third party 
            access to your account that results from theft or misappropriation of your account. The Company and its associates reserve the right to refuse 
            or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>

        <a className='toc' href="#ChildrenUnderSixteen" name="ChildrenUnderSixteen">
        <h3>Children Under Sixteen</h3></a>
        <p>The Company does not knowingly collect personally identifiable information from children under the age of 16. If you are under the age of 16, 
            you are not permitted to use this application.</p>
        
        <a className='toc' href="#TrialsOffersAuto-RenewalsPaidAccounts" name="TrialsOffersAuto-RenewalsPaidAccounts">
        <h3>Trial Offers, Auto-Renewals and Paid Accounts</h3></a>
        <p>The Company may make available trial offers for the limited use of the Product (each, a "Trial Offer") to new customers who provide a valid 
            payment instrument, such as a credit card or other payment method. If you choose to start a Trial Offer, you will automatically join the paid 
            plan you have chosen at the end of the Trial Offer and you authorize the Company to charge your payment method a recurring monthly fee or annual 
            fee, as applicable. If you wish to use additional services beyond those included in the Trial Offer, you may be asked to separately purchase 
            those services or to upgrade your plan prior to the end of the Trial Offer period. Please visit https://buildxcl.com/ for additional details on 
            our available plans. We reserve the right to change our pricing from time to time, but our price changes will always be prospective 
            and not retroactive.</p>
        <p>To ensure uninterrupted service and to enable you to conveniently purchase additional products and services, The Company will store and update 
            (e.g. upon expiration) your payment method on file. If we are unable to charge your designated payment method for any reason, we reserve the right 
            to suspend or cancel your subscription and delete your content, including brand profiles and learning links, in the event of non-payment. Please 
            note that it is your responsibility to maintain current billing information on file with Build Capable XCL.</p>

        <a className='toc' href="#CancellationsandRefundPolicy" name="CancellationsandRefundPolicy">
        <h3>Cancellations & Refund Policy</h3></a>
        <p>Any content stored in XCL, including brand profiles and learning links, will remain active with an active subscription. If you cancel your subscription, 
            all content will be deleted at the end of your current subscription period. You may also request to delete your account and all information will be 
            deleted at the time of your request. We do not provide partial refunds for early termination of a subscription, but you may contact us at 
            <a href="mailto:contact@buildcapable.com">contact@buildcapable.com</a> if you believe that you are entitled to a refund. Refund requests will be 
            reviewed on a case-by-case basis.</p>

        <a className='toc' href="#TerminationofSubscriptionandAccessRestriction" name="TerminationofSubscriptionandAccessRestriction">
        <h3>Termination of Subscription and Access Restriction</h3></a>
        <p>The Company reserves the right, in its sole discretion, to terminate your access to the
        Product, for any reason and at any time without prior notice. For example, we may suspend or terminate your use of some or all of the Services if you violate 
        these Terms or use the Product in a manner that may expose us to legal liability. If the Company elects to terminate your subscription, the Company will provide 
        you with notice at your registered email address. The Company also reserves the right to modify or discontinue, either temporarily or permanently, any part of 
        its Product with notice. You agree that the Company will not be liable to you or to any third party for any modification, suspension, or discontinuance of your 
        subscription or access to the Product.</p>

        <a className='toc' href="#LinkstoThirdPartySitesThirdPartyServices" name="LinkstoThirdPartySitesThirdPartyServices">
        <h3>Links to Third Party Sites/Third Party Services</h3></a>
        <p>The Company's Product may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Build Capable XCL and the Company 
            is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a 
            Linked Site. Build Capable XCL is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by the 
            Company of the site or any association with its operators.</p>
        <p>Certain services made available via the Company's Product are delivered by third party sites and organizations. By using any product, service, or functionality 
            originating from the https://www.buildxcl.com/ domain, you hereby acknowledge and consent that Build Capable XCL may share such information and data with 
            any third party with whom the Company has a contractual relationship to provide the requested product, service, or functionality on behalf of the Company's 
            Product's users and customers.</p>

        <a className='toc' href="#NoUnlawfulorProhibitedUseIntellectualProperty" name="NoUnlawfulorProhibitedUseIntellectualProperty">
        <h3>No Unlawful or Prohibited Use/Intellectual Property</h3></a>
        <p>You are granted a non-exclusive, non-transferable, revocable license to access and use the Company's Product strictly in accordance with these terms of 
            use. As a condition of your use of the Site, you warrant to the Company that you will not use the Product for any purpose that is unlawful or prohibited 
            by these Terms. You may not use the Product in any manner which could damage, disable, overburden, or impair the Product or interfere with any other 
            party's use and enjoyment of the Product. You may not obtain or attempt to obtain any materials or information through any means not intentionally made 
            available or provided for through the Product.</p>
        <p>All content included as part of the Product, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the 
            Site, is the property of the Company or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary 
            rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any
        such content and will not make any changes thereto.</p>
        <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of 
            the content, in whole or in part, found on the Site. Build Capable XCL content is not for resale. Your use of the Product does not entitle you to make 
            any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any 
            content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission 
            of the Company and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do
        not grant you any licenses, express or implied, to the intellectual property of the Company, the Company's Product, or our licensors except as expressly 
        authorized by these Terms.</p>

        <a className='toc' href="#OwnershipandPreservationofYourContent" name="OwnershipandPreservationofYourContent">
        <h3>Ownership and Preservation of Your Content</h3></a>
        <p>The Company does not claim ownership of any content you either create or upload and store or link to using our Product. You acknowledge and agree that 
            the Company may preserve your Content as well as disclose them if required to do so by law or in the good faith belief that such preservation or 
            disclosure is reasonably necessary to accomplish any of the following: (1) to comply with legal process, applicable laws or government requests; (2) 
            to enforce these Terms; (3) to respond to claims that any content violates the rights of third parties; or (4) to protect
        the rights, property, or personal safety of the Company, Build Capable XCL, its users and the public. You agree that the Company has no responsibility 
        or liability for deleting or failing to store any content maintained or uploaded in the Product.</p>

        <a className='toc' href="#InternationalUsers" name="InternationalUsers">
        <h3>International Users</h3></a>
        <p>The Product is controlled, operated, and administered by the Company from our offices within the USA. If you access the Service from a location outside 
            the USA, you are responsible for compliance with all local laws. You agree that you will not use the Product or the content accessed through the 
            Company's Product in any country or in any manner prohibited by any applicable laws, restrictions, or regulations.</p>

        <a className='toc' href="#Indemnification" name="Indemnification">
        <h3>Indemnification</h3></a>
        <p>You agree to indemnify, defend, and hold harmless the Company, its officers, directors, employees, agents, and third parties, for any losses, costs, 
            liabilities, and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Product, any user 
            postings made by you, your violation of any of these Terms, or your violation of any rights of a third party, or your violation of any applicable 
            laws, rules or regulations. The Company reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise 
            subject to indemnification by you, in which event you will fully cooperate with the Company in asserting any available defenses.</p>

        <a className='toc' href="#Arbitration" name="Arbitration">
        <h3>Arbitration</h3></a>
        <p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms, or any provisions hereof, whether 
            in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding 
            arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, 
            or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, 
            and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration 
            arises out of or concerns these Terms, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree 
            to arbitrate all disputes and claims in regards to these Terms or any disputes arising as a result of these Terms, whether directly or indirectly, 
            including Tort claims that are a result of these Terms. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement 
            of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. 
            This arbitration provision shall survive the termination of these Terms.</p>

        <a className='toc' href="#ClassActionWaiver" name="ClassActionWaiver">
        <h3>Class Action Waiver</h3></a>
        <p>Any arbitration under these Terms will take place on an individual basis; class arbitrations
        and class/representative/collective actions are not permitted. THE PARTIES AGREE
        THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S
        INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
        PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS
        IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.
        Further, unless both you and the Company agree otherwise, the arbitrator may not
        consolidate more than one person's claims, and may not otherwise preside over any form
        of a representative or class proceeding.</p>

        <a className='toc' href="#LiabilityDisclaimer" name="LiabilityDisclaimer">
        <h3>Liability Disclaimer</h3></a>
        <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES 
            ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. LEARNING NINJAS LLC DBA BUILD CAPABLE AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN 
            THE SITE AT ANY TIME.</p>
        <p>LEARNING NINJAS LLC DBA BUILD CAPABLE AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND 
            ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED 
            BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS ARE</p>
        <p>PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. LEARNING NINJAS LLC DBA BUILD CAPABLE AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES 
            AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF 
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
        <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL LEARNING NINJAS LLC DBA BUILD CAPABLE AND/OR ITS SUPPLIERS BE LIABLE FOR ANY 
            DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS 
            OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE 
            OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS OBTAINED 
            THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, 
            EVEN IF LEARNING NINJAS LLC DBA BUILD CAPABLE OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS 
            DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE 
            DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>

        <a className='toc' href="#GoverningLawAndJurisdictionsEntireAgreementSeverability" name="GoverningLawAndJurisdictionsEntireAgreementSeverability">
        <h3>Governing Law and Jurisdiction; Entire Agreement; Severability</h3></a>
        <p>To the maximum extent permitted by law, this agreement is governed by the laws of the State of Texas and you hereby consent to the exclusive jurisdiction 
            and venue of courts in Texas in all disputes arising out of or relating to the use of the Product. Use of the Product is unauthorized in any jurisdiction 
            that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
        <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and the Company as a result of this agreement or use of 
            the Product. The company's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in 
            derogation of the Company's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Product or 
            information provided to or gathered by the Company with respect to such use. If any part of this agreement is determined to be invalid or unenforceable 
            pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable 
            provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of 
            the agreement shall continue in effect.</p>
        <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and the Company with respect to the Product and it 
            supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between the user and the Company with respect 
            to the Product. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings 
            based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated 
            and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

        <a className='toc' href="#ChangestoTerms" name="ChangestoTerms">
        <h3>Changes to Terms</h3></a>
        <p>The Company reserves the right, in its sole discretion, to change the Terms under which the Company's Product is offered. The most current version of the 
            Terms will supersede all previous versions. The Company encourages you to periodically review the Terms to stay informed of our updates.</p>

        <h3 id="Contact">Contact</h3>
        <p>The Company welcomes your questions or comments regarding this Policy. If you believe that the Company has not adhered to this Policy, please contact the 
            Company at:</p>
        <p>
            Learning Ninjas LLC dba Build Capable<br/>
            5900 Balcones Drive STE 13207<br/>
            Austin, Texas 78731
        </p>
        <p>
            Email Address:<br/>
            <a href="mailto:contact@buildcapable.com">contact@buildcapable.com</a><br/>
            Phone Number:<br/>
            844-844-1595
        </p>
        <div className='backtotop floating'>
            <a href="#tableOfContents"><img src={backtotopIcon} alt={backtotopIconAlt}/></a>
            <a className='toplink' href="#tableOfContents">Back to Top</a>
        </div>
        </div>
        <div className="helpPageTile">
          <div className="titleBlock">
            <h2>
            Contact us with any
            questions about our terms of use.<br/>
            <span className='titleEmphasis'>  
              We're here to help.</span>
            </h2>
            <img src={contactUsIcon} alt={contactUsIconAlt} />
          </div>
          <div className="titleBlock">
            <div className='contactInfo'>
              <h2>Contact Us</h2>
              <div>
                <span className='contact-icons icon-phone-fill'/>
                <strong>Phone</strong>: <a href="tel:+1 844 844 1595">+1 844 844 1595</a>
              </div>
              <div>
                <span className='contact-icons icon-email-fill'/>
                <strong>Email</strong>: <a href="mailto:contact@buildcapable.com">contact@buildcapable.com</a>
              </div>
              <div>
                <span className='contact-icons icon-pin-fill' />
                <strong>Address</strong>: 5900 Balcones Drive Suite 13207 Austin, TX 78731
              </div>
              <div>
                <span className='contact-icons icon-linkedin-fill'/>
                <a href="https://www.linkedin.com/company/buildcapable/" target='_blank' rel="noreferrer">Build Capable on LinkedIn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default TermsAndConditions;