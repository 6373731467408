import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../img/XCL_White_logolockup.png';
import iconMyaccount from '../css/icons/icon-myaccount.png';

const logoAlt = "Build Capable XCL Content Links Logo";
const iconMyaccountAlt = "My Account icon";

const Header = () => {
const [currSel, setSelection]   = useState("");

    const location = useLocation();
  
    useEffect(() => {
      setSelection(location.pathname);
    }, [location]);
  
    const clearEdits = () => {
        const ls = localStorage;
        ls.setItem("editProfileID","");
        ls.setItem("editLinkID","");
    }

    return (
        <header>
            <a href="#contentstarts" className="skipnavigaton">Skip to main content</a>
            <Link to="/xcl/" onClick={clearEdits} className="headerHomeLink">
                <img src={logo} alt={logoAlt} className='xcl-logo' />
            </Link>
            <div className='version' name='version' alt=''>{process.env.REACT_APP_VERSION}</div>

            <Link className={(currSel === "/xcl/help") ? "helpLink active" : "helpLink"} id='helpLinkObj' to="/xcl/help" onClick={clearEdits}>Help & Support</Link>
            <Link className={(currSel === "/xcl/account") ? "myAccountLink active" : "myAccountLink"} to="/xcl/account" onClick={clearEdits}>
                <img src={iconMyaccount} alt={iconMyaccountAlt} className='myAccountLinkIcon' /> My Account
            </Link>
        </header>
    )

}

export default Header;