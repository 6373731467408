const cssClip = `
table.listtable {
    width:100%;
    border-radius: 10px;
    padding:0px;
    margin:0px;
    border-spacing: 0px;
    border:none;
    overflow:hidden;
    background-color:#fff;
}
.listtable > tbody > tr > th {
    background-color: #0F172A;
    color: #fff;
    font-weight: 400;
    font-size: 1.125rem;
    text-align:left;
    line-height: 100%;
    height:60px;
    padding-left: 24px
}
.listselector.master, .listselector {
    text-align: center !important;
    line-height:250%;
}
.listtable > tbody > tr > td {
    text-align:left;
    line-height: 100%;
    height:60px;
    padding-left: 24px
}

.listtable > tbody > tr > td > imput[type='checkbox'] {
    height:24px;
    width: 24px;
    border-radius: 2px;
}
.listtable > tbody > tr > th > img {
    margin: 0px 0px 2px 6px;
}
.listtable > tbody > tr {
    height: 85px;
}
.listtable > tbody > tr:hover {
    background-color: #EEE;
}
.stophover {
    background-color: #FFF;
}
.emptyTable {
    height:428px;
    text-align: center;
    padding: 0px 15%;
}
.emptyTable > img {
    margin: 48px auto 6px auto;
}
.emptyTable h3, .emptyTable p {
    text-align: center;
}
.emptyTable > a {
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    border-radius: 10px;
    color: #F8FAFC;
    border: none;
    background-color: #00606B;
    padding: 12px 18px;
    cursor: pointer;
    text-decoration:none;
    display: inline-block;
    margin: 25px 0px 0px 0px;
    height: 21px;
}
.emptyTable.links > a {
    background-color: #41206C;
}
.emptyTable > p > a {
    font-weight: 700;
    color: #513AD1;
}

.logolink {
    border-radius: 6px;
    display:block;
    padding:2px;
    text-align:center;
}

`;

export default cssClip;