import ScrollToTop from '../components/ScrollToTop';
import contactUsIcon from '../img/contactus_icon.png';
import validate from '../xcl_modules/validate';
import { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";

const contactUsIconAlt = "We're here to help Icon"

const AccessibilityStatement = () => {
  
  const [navOpen] = useOutletContext();

  useEffect(() => {
    validate();
  }, []);

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <a name="contentstarts" className='contentStarts' href='#start'>
        <h1 name="start">
          Accessibility Statement
        </h1>
      </a>
      <h4>
        Learn about our commitment to accessibility and how we ensure our platform is inclusive for everyone.
      </h4>
      <div className="helpPageTile">
        <h3>Measures to support accessibility</h3>
        <p>Build Capable takes the following measures to ensure accessibility of <a href="https://buildxcl.com">https://buildxcl.com</a>:</p>
        <ul>
          <li>Include accessibility as part of our mission statement.</li>
          <li>Include accessibility throughout our internal policies.</li>
          <li>Integrate accessibility into our procurement practices.</li>
          <li>Provide continual accessibility training for our staff.</li>
          <li>Assign clear accessibility goals and responsibilities.</li>
          <li>Employ formal accessibility quality assurance methods.</li>
          <li>Include people with disabilities in our design personas.</li>
        </ul>
        <h3>Conformance status</h3>
        <p>The <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" target='_blank' rel="noreferrer">Web Content Accessibility Guidelines (WCAG)</a> defines 
            requirements for designers and developers
            to improve accessibility for people with disabilities. It defines three levels of conformance: 
            Level A, Level AA, and Level AAA. Although we strive for WCAG 2.1 level AA, some parts of 
            buildxcl.com, such as older blog posts, may not fully conform to the accessibility standard.</p>
        <h3>Technical specifications</h3>
        <p>Accessibility of buildxcl.com relies on the following technologies to work with the particular combination of web browser 
          and any assistive technologies or plugins installed on your computer:</p>
        <ul>
          <li>HTML</li>
          <li>CSS</li>
          <li>JavaScript</li>
        </ul>
        <p>These technologies are relied upon for conformance with the accessibility standards used. This site works best on Google Chrome browser.</p>
        <h3>Assessment approach</h3>
        <p>Build Capable assessed the accessibility of buildxcl.com by the following approaches:</p>
        <ul>
          <li>Self-evaluation</li>
        </ul>
        <h3>Formal approval of this accessibility statement</h3>
        <p>This Accessibility Statement is approved by:<br/>
        Sarah Mercier<br/>
        Chief Executive Officer, Build Capable</p>

      </div>
      <div className="helpPageTile">
        <div className="titleBlock">
          <h2>
            Let us know if you encounter accessibility barriers.<br/><span className='titleEmphasis'>  
            We're here to help.</span>
          </h2>
          <img src={contactUsIcon} alt={contactUsIconAlt} />
        </div>
        <div className="titleBlock">
          <div className='contactInfo'>
            <h2>Contact Us</h2>
            <div>
              <span className='contact-icons icon-phone-fill'/>
              <strong>Phone</strong>: <a href="tel:+1 844 844 1595">+1 844 844 1595</a>
            </div>
            <div>
              <span className='contact-icons icon-email-fill'/>
              <strong>Email</strong>: <a href="mailto:contact@buildcapable.com">contact@buildcapable.com</a>
            </div>
            <div>
              <span className='contact-icons icon-pin-fill' />
              <strong>Address</strong>: 5900 Balcones Drive Suite 13207 Austin, TX 78731
            </div>
            <div>
              <span className='contact-icons icon-linkedin-fill'/>
              <a href="https://www.linkedin.com/company/buildcapable/" target='_blank' rel="noreferrer">Build Capable on LinkedIn</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityStatement;