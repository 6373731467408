const cssClip = `
.lpContainer {
  display: flex;
  border-radius: 20px;
  border: 1px solid #CBD5E1;
  height: 610px;
  margin: 0px 0px 59px 0px;
  width: 100%;
  align-items: center;
}

.lpHide {
  display: none !important;
}

.lpMobileContainer {
  display: block;
  width:306px;
  height:652px;
  margin: 27px auto 59px auto;
  padding: 14px 14px 13px 16px;
  background-color: transparent;
}

.lpContainer.mobile {
  border-radius: 40px;
  margin: 0px;
  padding: 16px 16px;
  height:calc(100% - 32px);
  border:none;
  width: 272px;
}

.lpLogo {
  width: 100%;
  height: 80px;
  margin: 0px 0px 15px 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.lpTile {
  display: block;
  width: calc(36% - 106px);
  min-width: 161px;
  margin: 29px auto;
  box-shadow: 0px 13.27px 26.54px 0px #606170A6;
  background-color: #f2f5f5;
  border-radius: 20px;
  padding: 32px 53px;
}

.lpTile.mobile {
  width: 80%;
  min-width: 0px;
  padding: 24px;
}

.lpTile > h1 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-align: center;
  color: #0F172A;
}

.lpTile > h2 {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 0.813rem;
  text-align: center;
  color: #0F172A;
  margin: 14px 0px;
}

.lpTile > div > label {
  font-size: 0.563rem;
  font-weight: 400;
  line-height: 0.813rem;
  text-align: left;
  color: #475569;
  margin: 0px;
}

.lpTile > div > input[type='text'] {
  width: calc(100% - 22px);
  height: auto;
  margin: 2px 0px 16px 0px;
  padding: 9px 10px;
  border-radius: 7px;
  border: 1px solid #94A3B8;
  font-size:0.625rem;
  color:#0F172A;
}
.lpTile > div > input[type='text']:focus {
  border: 1.5px solid #94A3B8;
}
.lpTile > div > input[type='text']::placeholder {
  color: #475569;
}

input[type='button'].previewSubmit {
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.813rem;
  width: 100%;
  padding: 9px 0px;
  margin: 0px;
  border-radius: 7px;
  background-color: #0F172A;
  color: #F8FAFC;
  border: 0px;
  text-align: center;
  background-image: none;
  font-family: 'Lato';
  height: auto;
}

.lpTile > div > input[type='button'] {
  font-weight: 700;
  font-size: 0.688rem;
  line-height: 1rem;
  width: 100%;
  margin-top: 16px;
  background-color: transparent;
  color: #513AD1;
  border: 0px;
  outline: 0px;
}

.lpTile > div > input[type='button']:hover {
  text-decoration: underline;
}

.lpControls {
  position:relative;
  top:0px;
  float:right;
  display: block;
  border: 1px solid #64748B;
  border-radius: 10px;
  padding: 7px 7px 7px 0px;
}

.lpControls > input[type='button'] {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 10px;
  background-color: #FFF;
  color: #0F172A;
  text-align: center;
  border: 0px;
  padding: 11px;
  width:110px;
  cursor: pointer;
  margin: 0px 0px 0px 7px;
}

.lpControls > input[type='button'].active {
  background-color: #0F172A;
  color: #FFFFFF;
}
    `;

export default cssClip;