export const ErrorMessage = (props) => { 
    if (props.content !== "") {
        return (
            <p name="login-error" className="loginError" role="alert">{props.content}</p>
        )
    } else {
        return (
            false
        )
    }
};
export const SuccessMessage = (props) => { 
    if (props.content !== "") {
        return (
            <p name="login-success" className="loginSuccess" role="alert">{props.content}</p>
        )
    } else {
        return (
            false
        )
    }
};