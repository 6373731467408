import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import iconModalConfirm from '../css/icons/icon-modal-confirm.png';


const ConfirmDelete = (props) => {
    
    const number = parseInt(props.number);

    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            { (number === 0) ? (
                <>
                    <div className='confirmIcon' style={{ backgroundImage: `url('${iconModalConfirm}')` }}></div>
                    <div className='modalContent'>
                        <h2>Delete {props.object}</h2>
                        <p>Do you want to permanently delete the following {props.object}?</p>
                        <p className='emphasized'>{props.objectname}</p>
                        <p className='warning'>This action cannot be undone.</p>
                    </div>            
                    <div className='controls'>
                        <Link className='cancelBtn' onClick={props.onClose}>Cancel</Link>
                        <Link className='confirmBtn' onClick={props.onConfirm}>Delete</Link>
                    </div>
                </>
            ) : (
                <>
                    <div className='confirmIcon' style={{ backgroundImage: `url('${iconModalConfirm}')` }}></div>
                    <div className='modalContent'>
                        <h2>Delete {props.object}</h2>
                        <p>We're sorry, but you cannot delete this {props.object}, there { (number === 1) ? 'is' : 'are' } currently {props.number} Learning Link{ (number === 1) ? '' : 's' } using it.</p>
                    </div>            
                    <div className='controls'>
                        <Link className='cancelBtn' onClick={props.onClose}>OK</Link>
                    </div>
                </>
            )}
        </Popup>
    );


}

export default ConfirmDelete;