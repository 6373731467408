import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useEffect } from 'react';

const NoPage = () => {

  useEffect(() => {
    validate();
  }, []);

  return (
      <>
        <ScrollToTop/>
        <div className='contentOuterHome' styl>
          <h1>Oops, there's no page with that name here.</h1>
        </div>
      </>);
  };
  
  export default NoPage;
  