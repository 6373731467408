import logo from '../img/xcl-logo.png';
import Profiles from '../components/Loginprofiles';
import { useNavigate } from 'react-router-dom';

const logoAlt = "Build Capable XCL Content Links Logo";

const rand = Math.random() * Profiles.length;
let thisprofileN = Math.floor(rand);

const ThankYou = () => {

    const nav = useNavigate();

    const doSubmit = () => {
        nav("/login");
    }

    return (
        <>
            <img className="logo" src={logo} alt={logoAlt} />
            <div title="Patent Statement" className='patentMessage login'>{process.env.REACT_APP_PATENT_MESSAGE}</div>
            <form onSubmit={doSubmit} className='loginForm'>
                <h1 className='login'>Thank you for signing up with US!</h1>
                <h2 style={{ margin:"24px 0px", fontSize: "1.2rem", lineHeight: "130%", fontWeight: "500" }}>
                    Once your account is set up and ready to go, we'll send you an email with a link to 
                    create a password. Once you've done that, you can come back and login for the first time! 
                    We're excited to get you started.
                </h2>
                <input type="submit" value="Log in" name="dologin"></input>
            </form>
            <div className="quotePanel">
                <img className="profilepic" src={Profiles[thisprofileN].img} alt={Profiles[thisprofileN].name} />
                <div className="quote">
                    “{Profiles[thisprofileN].tag}”
                </div>
                <div className="byline">
                    {Profiles[thisprofileN].name}
                </div>
            </div>
            <div className="quotePanel">
                <img className="profilepic" src={Profiles[thisprofileN].img} alt={Profiles[thisprofileN].name} />
                <div className="quote">
                    “{Profiles[thisprofileN].tag}”
                </div>
                <div className="byline">
                    {Profiles[thisprofileN].name}
                </div>
            </div>
        </>

    );
};

export default ThankYou;