import getUserData from './userData';
import getTokenData from './tokenData';

const validate = async () => {
    
    const userData = getUserData();
    const tokenData = getTokenData();

    let isValid = false;

    const getData = async () => { return fetch(process.env.REACT_APP_BE+'/validate', {
        method: 'GET',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${tokenData.accessToken}`}
    }).catch(error => {
        console.log(error);
        console.log("the API may be down");
    })};

    const newData = async () => { return fetch(process.env.REACT_APP_BE+'/refreshToken', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify( { "uid": userData.userID, "token": tokenData.refreshToken } )
    })};

    let thisDataRes = await getData();
    let thisData = await thisDataRes.json();

    if (thisData.code === 444) {
        // token is bad, try with the refresh token

        const thatDataRes = await newData();
        const thatData = await thatDataRes.json();

        if (thatData.status === "success") {
            // good new tokens in data let's commit them to storage
            window.localStorage.setItem("xcltokens", JSON.stringify(thatData.tokens) );
            tokenData.accessToken = thatData.tokens.accessToken;
            tokenData.refreshToken = thatData.tokens.refreshToken;
            console.log("tokens refreshed");
            // try again
            thisDataRes = await getData();
            thisData = await thisDataRes.json();

            if (thisData.code === 444) { 
                console.log("tried refresh twice to no avail.");
                document.location = "/login";
            } else {
                if (thisData.status === "success") {
                    isValid = true;
                }
            }
        } else {
            // go do another login
            if (document.location.href.indexOf("/login") > 0) {
                console.log(document.location.href + " says please log in");
            } else {
                document.location = "/login";
            }
        }
    } else {

        if (thisData.status === "success") {
            isValid = true;
        }
    }
    
    return isValid;
};

export default await validate;