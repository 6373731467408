import emojiWavingHand from '../css/icons/emoji-wavinghand.png';
import linkprofilesBg from '../img/linkprofiles_bg.png';
import linksBg from '../img/links_bg.png';
import helptileBg from '../img/helptile_bg.png';
import admintileBg from '../img/admintile_bg.png';
import getUserData from '../xcl_modules/userData';
import {homeMessage, homeSubtitle} from '../xcl_modules/homemessages';
import { Link, useOutletContext } from "react-router-dom";
import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useEffect } from 'react';

const emojiWavingHandAlt = "Waving hand emoji icon";

const Home = () => {
  const userData = getUserData();
  const homeMsg = homeMessage();
  const homeSub = homeSubtitle();

  const [navOpen] = useOutletContext();

  useEffect(() => {
    validate();
  }, []);

  return (
    <>
      <ScrollToTop/>
      <div className={'contentOuterHome ' + ((navOpen) ? 'openNav' : '') }>
        <h1>
          <img src={emojiWavingHand} alt={emojiWavingHandAlt} className='iconInline' />
          <a name="contentstarts" className='contentStarts' href='#contentstarts' style={{ color: "#0F172A" }}>
          Welcome {userData.firstname}. {homeMsg}
          </a>  
        </h1>
        <h4>
          {homeSub}
        </h4>
        <div className='homeSbS'>
          <div className='homeTile linkprofiletile' style={{backgroundImage: `url(${linkprofilesBg})`}}>
            <h3>Brand Templates</h3>
            <p>Create, manage and update all your brand templates here.</p>
            <Link to='/xcl/profiles' className='letsgo'>Open Brand Templates</Link>
          </div>
          <div className='homeTile linktile' style={{backgroundImage: `url(${linksBg})`}}>
            <h3>Learning Links</h3>
            <p>Set-up  and manage all your learning links here. </p>
            <Link to='/xcl/links' className='letsgo'>Open Learning Links</Link>
          </div>
        </div>
        <div className='homeTileLarge helptile' style={{backgroundImage: `url(${helptileBg})`}}>
          <h3>Have a Question?</h3>
          <p>Check out our FAQs for quick answers or use our contact form for personalized support.</p>
          <Link to='/xcl/help' className='letsgolt'>Open Help & Support</Link>
        </div>
        <div className='homeTileLarge admintile' style={((userData.role===1) ? { backgroundImage: `url(${admintileBg})` } : { display: "none" } )}>
          <h3>Admin Settings</h3>
          <p>Welcome to the privilege of being a System Admin! Change system settings here, but be warned, these settings affect all links everywhere all at once.</p>
          <Link to='/xcl/adminsettings' className='letsgolt'>Open Admin Settings</Link>
        </div>
      </div>
    </>
  )
};

export default Home;
  