
const XCL = () => {

    return (
        <>
            <div style={{ maxWidth: "1200px", margin: "48px auto 12px auto", backgroundColor: "#ECECEC", padding: "24px" }}>
                <div style={{ color: "#333333", fontSize: "1rem", margin: "24px 0px" }}>
                    <h1 style={{ textAlign: "center" }}>The marketing site, please select a plan.</h1>
                </div>
                <style>
                    { (`
                        .plan {
                            width: 27%;
                            padding: 14px;
                        }
                        `)}
                </style>
                <div style={{ margin: "12px 0px", display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    <div className="plan" style={{ backgroundColor: "#f1f5f9" }}>
                        <h1>Trial</h1>
                        <h3>For developers who would like to test using XCL.</h3>
                        <ul>
                            <li>1 Learning Link</li>
                            <li>1 Brand Template</li>
                            <li>Unlimited Learners</li>
                            <li>250MB content storage</li>
                            <li>Host your own content</li>
                            <li>Built-in LRS (or use your own)</li>
                            <li>Upgrade to Build after trial period</li>
                        </ul>
                        <a href="https://buildxcl-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Trial-USD-Weekly&utm_source=cb-app-copy">Free 14 days</a>
                    </div>
                    <div className="plan" style={{ backgroundColor: "#e2e8f0" }}>
                        <h1>Build</h1>
                        <h3>For smaller organizations with a limited amount of content.</h3>
                        <ul>
                            <li>10 Learning Links</li>
                            <li>10 Brand Templates</li>
                            <li>Unlimited Learners</li>
                            <li>250MB content storage</li>
                            <li>Host your own content</li>
                            <li>Built-in LRS (or use your own)</li>
                            <li>Tiered upgrades available</li>
                        </ul>
                        <a href="https://buildxcl-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Basic-USD-Monthly&utm_source=cb-app-copy">Pay only $49 / month</a><br/>
                        <a href="https://buildxcl-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Basic-USD-Yearly&utm_source=cb-app-copy">or $499 yealry (15% Discount)</a>
                    </div>
                    <div className="plan" style={{ backgroundColor: "#c8d5e1" }}>
                        <h1>Enterprise</h1>
                        <h3>For organizations who want to launch content and access robust reporting.</h3>
                        <h4>Build level plus:</h4>
                        <ul>
                            <li>1 Learning Link</li>
                            <li>1 Brand Template</li>
                            <li>Unlimited Learners</li>
                            <li>250MB content storage</li>
                            <li>Host your own content</li>
                            <li>Built-in LRS (or use your own)</li>
                            <li>Upgrade to Build after trial period</li>
                        </ul>
                        <a href="/xcl">Contact Us for Pricing</a>
                    </div>
                </div>
                <div style={{ margin: "24px 44px" }}>
                    Already have an account? <a href="/login">Login</a>
                </div>
            </div>
        </>
    );
};

export default XCL;
  