import getUserData from './userData';
import getTokenData from './tokenData';

export const getAdminData = async () => {
    
    const userData = getUserData();
    const tokenData = getTokenData();

    const getData = async () => { return fetch(process.env.REACT_APP_BE+'/settings', {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 
            Authorization: `Bearer ${tokenData.accessToken}`}
    }).catch(error => {
        console.log("the API may be down");
    })};

    const newData = async () => { return fetch(process.env.REACT_APP_BE+'/refreshToken', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify( { "uid": userData.userID, "token": tokenData.refreshToken } )
    })};

    let thisDataRes = await getData();
    let thisData = await thisDataRes.json();

    if (thisData.code === 444) {
        // token is bad, try with the refresh token

        const thatDataRes = await newData();
        const thatData = await thatDataRes.json();

        if (thatData.status === "success") {
            // good new tokens in data let's commit them to storage
            window.localStorage.setItem("xcltokens", JSON.stringify(thatData.tokens) );
            tokenData.accessToken = thatData.tokens.accessToken;
            tokenData.refreshToken = thatData.tokens.refreshToken;
            // try again
            thisDataRes = await getData();
            thisData = await thisDataRes.json();

            if (thisData.code === 444) { 
                console.log("tried refresh twice to no avail.");
                document.location = "/login";
            }
        } else {
            // go do another login
            if (document.location.href.indexOf("/login") > 0) {
                console.log(document.location.href + " says please log in");
            } else {
                document.location = "/login";
            }
        }
    }
    
    return await thisData;
};

export const saveAdminData = async (formData) => {
    
    const userData = getUserData();
    const tokenData = getTokenData();

    const getData = async (appid, value) => { return fetch(process.env.REACT_APP_BE+'/setting', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${tokenData.accessToken}`},
        body: JSON.stringify( { "appid": appid, "value": value } )
    })};
    
    const newData = async () => { return fetch(process.env.REACT_APP_BE+'/refreshToken', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify( { "uid": userData.userID, "token": tokenData.refreshToken } )
    })};

    let messages = [];

    for (var key in formData) {
        // skip loop if the property is from prototype
        if (!formData.hasOwnProperty(key)) continue;
    
        const val = formData[key];
        
        let thisDataRes = await getData(key, val);
        let thisData = await thisDataRes.json();

        if (thisData.code === 444) {
            // token is bad, try with the refresh token
            const thatDataRes = await newData();
            const thatData = await thatDataRes.json();
    
            if (thatData.status === "success") {
                // update tokens
                window.localStorage.setItem("xcltokens", JSON.stringify(thatData.tokens) );
                tokenData.accessToken = thatData.tokens.accessToken;
                tokenData.refreshToken = thatData.tokens.refreshToken;

                // try again
                thisDataRes = await getData(key, val);
                thisData = await thisDataRes.json();

                if (thisData.code === 444) { 
                    // booooo it really didn't work
                    console.log("tried refresh twice to no avail.");
                    document.location = "/login";
                }

            } else {
                // go do another login
                if (document.location.href.indexOf("/login") > 0) {
                    console.log(document.location.href + " says please log in");
                } else {
                    document.location = "/login";
                }
                break; 
            }
        }
        
        messages.push(`${formData[key]}-${thisData.message}`);
    }
    



    
    return messages;
};