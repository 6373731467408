import logo from '../img/xcl-logo.png';
import { useState } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import profiles from '../components/Loginprofiles';
import { useSearchParams } from "react-router-dom";
import { ErrorMessage, SuccessMessage } from '../components/InlineMessages';

const logoAlt = "Build Capable XCL Content Links Logo";

const rand = Math.random() * profiles.length;
let thisprofileN = Math.floor(rand);

const ResetPassword = () => {
    const [inputs, setInputs] = useState({});
    const [errorMsg, setError] = useState("");
    const [successMessage, setSuccess] = useState("");
    const [passwordMismatch, setpasswordMismatch] = useState("");

    const [searchParams] = useSearchParams();

    const cpHash = searchParams.get("l");
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleCheckPassRetype = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        if (event.target.value === inputs.newpassword) {
            setpasswordMismatch("goodentry");
        } else {
            setpasswordMismatch("badentry");
        }
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (inputs.newpassword === inputs.newpassword_retype) {
            // send this server side to send the email from the forgot password path
            const fbRes = await fetch(process.env.REACT_APP_BE+'/changePassword', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify( { "hash": cpHash, "newpass": inputs.newpassword } )
            })

            const fbData = await fbRes.json();

            if (fbData.code === 1) {
                // success
                setError("");
                setSuccess("Your password has been reset successfully!");
            } else if (fbData.code === 0) {
                setSuccess("");
                setError ( fbData.message );
            }

        } else {
            setSuccess("");
            setError("Please make sure you've re-entered your password correctly");
        }
    }

    return (
        <>
            <ScrollToTop/>
            <img className="logo" src={logo} alt={logoAlt} />
            <form onSubmit={handleSubmit} className='loginForm'>
                <h1 className='login'>Reset Password</h1>
                <h5 className="instruction">Please enter your new password.</h5>
                <label htmlFor="newpassword">New Password</label>
                <input type="password" 
                    value={inputs.newpassword || ""} 
                    maxLength="70" 
                    name="newpassword" 
                    placeholder="Type your new password" 
                    onChange={handleChange}
                    autoFocus
                    disabled={ (successMessage==="") ? false : true }></input>
                <label htmlFor="newpassword_retype">Retype your new password</label>
                <input type="password" className={passwordMismatch}
                    maxLength="70" 
                    name="newpassword_retype" 
                    placeholder="Type your new password" 
                    onChange={handleCheckPassRetype}
                    disabled={ (successMessage==="") ? false : true }></input>
                <a className="loginlink" href="/login" alt="click here if you know your password and would like to log in">Go to Login</a>
                <input type="submit" value="Reset Password" name="dologin" disabled={ (successMessage==="") ? false : true }></input>
                <ErrorMessage content={errorMsg} />
                <SuccessMessage content={successMessage} />
                <p>Don't have an account? <a href={process.env.REACT_APP_XCLSIGNUP}>Sign Up</a></p>
            </form>
            <div className="quotePanel">
                <img className="profilepic" src={profiles[thisprofileN].img} alt={profiles[thisprofileN].name} />
                <div className="quote">
                    “{profiles[thisprofileN].tag}”
                </div>
                <div className="byline">
                    {profiles[thisprofileN].name}
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
  