const links = () => {
    let thisData = {};
    const ls = localStorage;

    if (ls.getItem("xcllinks")!==null && ls.getItem("xcllinks")!=="") {
        // fill the thing and JSON it
        thisData = JSON.parse( ls.getItem("xcllinks") );
    }

    return thisData;
};
  
export default links;