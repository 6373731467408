const profiles = () => {
    let thisData = {};
    const ls = localStorage;

    if (ls.getItem("xclprofiles")!==null && ls.getItem("xclprofiles")!=="") {
        // fill the thing and JSON it
        thisData = JSON.parse( ls.getItem("xclprofiles") );
    }

    return thisData;
};
  
export default profiles;