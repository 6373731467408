const startFieldsData ={
    "0": {
        "ID": 1,
        "parent": 130000,
        "fieldID": "firstname",
        "fieldname": "First Name",
        "type": "text",
        "label": "First Name",
        "placeholder": "Enter your first name",
        "visible": 1,
        "required": 1,
        "ordinal": 0,
        "status": 1
    },
    "1": {
        "ID": 2,
        "parent": 130000,
        "fieldID": "lastname",
        "fieldname": "Last Name",
        "type": "text",
        "label": "Last Name",
        "placeholder": "Enter your last name",
        "visible": 1,
        "required": 1,
        "ordinal": 1,
        "status": 1
    },
    "2": {
        "ID": 3,
        "parent": 130000,
        "fieldID": "email",
        "fieldname": "Email Address",
        "type": "email",
        "label": "Email Address",
        "placeholder": "youremail@example.com",
        "visible": 1,
        "required": 1,
        "ordinal": 2,
        "status": 1
    },
    "3": {
        "ID": 4,
        "parent": 130000,
        "fieldID": "userid",
        "fieldname": "User ID",
        "type": "text",
        "label": "User ID",
        "placeholder": "E.g. EMP-001234",
        "visible": 0,
        "required": 0,
        "ordinal": 3,
        "status": 1
    }
};

  export default startFieldsData