const tokenData = () => {

    let thisData = {};
    const loc = document.location;
    const ls = localStorage;

    if (ls.getItem("xcltokens")!=null) {
        // fill the thing and JSON it
        thisData = JSON.parse( ls.getItem("xcltokens") );
    } else {
        // go to login
        // document.location = "/login";
        if (loc.href.indexOf("/login") < 0) {
            loc.href = "/login";
        }
    }

    return thisData;
};
  
export default tokenData;