import contactUsIcon from '../img/contactus_icon.png';
import ScrollToTop from '../components/ScrollToTop';
import { useState, useEffect } from 'react';
import validate from '../xcl_modules/validate';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import { ErrorMessage, SuccessMessage } from '../components/InlineMessages';
import { useOutletContext } from "react-router-dom";
import FAQTiles from '../components/FAQTiles';

const contactUsIconAlt = "We're here to help Icon";

const Help = () => {
  const [inputs, setInputs] = useState({});
  const [errorMsg, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  
  const [navOpen] = useOutletContext();

  useEffect(() => {
    const userData = getUserData();
    Object.entries(userData).forEach(([key, val]) => {
      setInputs(values => ({...values, [key]: val }));
    });
    validate();
  }, []);

  const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
  }
    
  const doContactForm = async (event) => {
    event.preventDefault();

    if ((typeof(inputs.email)!=="undefined" || inputs.email!=="") || (typeof(inputs.firstname)!=="undefined" || inputs.firstname !== "") || (typeof(inputs.description)!=="undefined" || inputs.description !== "")) {

      const tokenData = getTokenData();

      let phonenumber = inputs.phone;
      if (typeof(phonenumber) === "undefined") {
        phonenumber = "na";
      } else if (phonenumber === "") {
        phonenumber = "na";
      }

      let bodyText = "A contact form has been filled out from the Help and Support page.\r\n\r\n";
      bodyText += "Customer: " + inputs.firstname + "\r\nPhone Number: " + phonenumber + "\r\nEmail: " + inputs.email + "\r\n\r\n";
      bodyText += "Message:\r\n" + inputs.description;

      const fbRes = await fetch(process.env.REACT_APP_BE+'/sendmail', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 
          Authorization: `Bearer ${tokenData.accessToken}`},
        body: JSON.stringify( { to: inputs.email + ",contact@buildcapable.com", subject: "XCL Contact Form Submission from Help and Support Page", bodytext: bodyText } )
      })

      const fbData = await fbRes.json();

      if (fbData.status === "success") {
        // success
        setError("");
        setSuccess("Your message has been sent successfully! We will get back to you in a timely manner.");
      } else if (fbData.code !== 444) {
        setSuccess("");
        setError ( fbData.message );
      } else {
        setSuccess("");
        setError ( "There was a problem, super sorry, try reloading the page and trying again." );
      }

    } else {
        setSuccess("");
        setError("Please make sure you've filled out each of the required fields, First Name, Email, and Description.");
    }


  }

  const doDiscard = (event) => {
    event.preventDefault();
    const name = "description";
    const value = "";
    setInputs(values => ({...values, [name]: value}));
  }

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <a name="contentstarts" className='contentStarts' href='#toc'>
        <h1>
          Help & Support
        </h1>
      </a>
      <h4>
      Browse our FAQs to find answers, or reach out to us directly with any questions or feedback.
      </h4>

      <FAQTiles/>

      <div className="helpPageTile">
        <div className="titleBlock">
          <h2>
            Leave us a question or send us some feedback. <span className='titleEmphasis'>  
            We're here to help.</span>
          </h2>
          <img src={contactUsIcon} alt={contactUsIconAlt} />
        </div>
        <form className="formBlock" onSubmit={doContactForm}>
          <div className='formColumn'>
            <label htmlFor="firstname">First name (Required)</label>
            <input type="text" 
              maxLength="120" 
              name="firstname" 
              placeholder="eg. Michael" 
              value={inputs.firstname || ""}
              onChange={handleChange}
              required
              disabled={ (successMessage==="") ? false : true }
              ></input>
          </div>
          <div className='formColumn column2'>
            <label htmlFor="phonenumber">Phone Number</label>
            <input type="tel" 
              maxLength="120" 
              name="phonenumber" 
              value={inputs.phone || ""}
              placeholder="eg. 1+ (555) 555-5555" 
              onChange={handleChange}
              disabled={ (successMessage==="") ? false : true }
              ></input>
          </div>
          <label htmlFor="email">Email (Required)</label>
          <input type="email" 
            maxLength="200" 
            name="email" 
            value={inputs.email || ""}
            placeholder="michael@example.com" 
            onChange={handleChange}
            required
            disabled={ (successMessage==="") ? false : true }
            ></input>
          <label htmlFor="description">Description (Required)</label>
          <textarea name='description'
            maxLength={1200}
            value={inputs.description || ""}
            rows={6}
            onChange={handleChange}
            required
            disabled={ (successMessage==="") ? false : true }
            >
          </textarea>
          <input type="submit" value="Send Message" name="dologin" style={{float:"right", marginLeft:"24px", marginTop:"14px"}} className='noSave' disabled={ (successMessage==="") ? false : true }></input>
          <input type="button" value="Discard" name="dologin" style={{float:"right", marginTop:"14px"}} disabled={ (successMessage==="") ? false : true } onClick={doDiscard}></input>
          <ErrorMessage content={errorMsg} />
          <SuccessMessage content={successMessage} />
        </form>
      </div>
    </div>
  );
};
  
export default Help;