import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import iconModalConfirm from '../css/icons/icon-modal-confirm.png';
import { useState } from 'react';

const ConfirmDeleteAccount = (props) => {
    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    
    const doSetFirstChallenge = (event) => {
        setFirst((event.target.checked) ? true : false);
    }
    
    const doSetSecondChallenge = (event) => {
        setSecond((event.target.checked)? true : false);
    }
    
    const doSetThirdChallenge = (event) => {
        setThird((event.target.checked)? true : false);
    }

    const doDelete = () => {
        if (first && second && third) {
            props.onDelete();
        }
    }

    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <form>
                <div className='confirmIcon' style={{ backgroundImage: `url('${iconModalConfirm}')` }}></div>
                <div className='modalContent'>
                    <h2 style={{ lineHeight: "110%", marginTop: "12px" }}>Please confirm you understand the following 
                    before your account can be deleted.</h2>
                    <input type="checkbox" name='first' onChange={doSetFirstChallenge} checked={first || ''} style={{ float: "left", width: "20px", marginRight: "8px" }} />
                    <label htmlFor='first' style={{ fontSize: "1rem" }}>I understand all branding material and learning links will be 
                    permanently deleted from my account.</label>
                    <input type="checkbox" name='second' onChange={doSetSecondChallenge} checked={second || ''} style={{ float: "left", width: "20px", marginRight: "8px" }} />
                    <label htmlFor='second' style={{ fontSize: "1rem" }}>I understand that any learning links in this account will become 
                    inaccessible.</label>
                    <input type="checkbox" name='third' onChange={doSetThirdChallenge} checked={third || ''} style={{ float: "left", width: "20px", marginRight: "8px" }} />
                    <label htmlFor='third' style={{ fontSize: "1rem" }}>I understand that this account will be permanently deleted and 
                    cannot be recovered.</label>
                </div>            
                <div className='controls' style={{ marginTop: "16px" }}>
                    <Link className='cancelBtn' onClick={props.onClose}>Cancel</Link>
                    <Link className={ (first && second && third) ? 'confirmBtn' : 'confirmBtn disabled' } onClick={doDelete}>Delete Account</Link>
                </div>
            </form>
        </Popup>
    );


}

export default ConfirmDeleteAccount;