import logo from '../img/xcl-logo.png';
import { useState } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import profiles from '../components/Loginprofiles';
import { ErrorMessage, SuccessMessage } from '../components/InlineMessages';

const logoAlt = "Build Capable XCL Content Links Logo";

const rand = Math.random() * profiles.length;
let thisprofileN = Math.floor(rand);

const ForgotPassword = () => {
    const [inputs, setInputs] = useState({});
    const [errorMsg, setError] = useState("");
    const [successMessage, setSuccess] = useState("");

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        // send this server side to send the email from the forgot password path
        const fbRes = await fetch(process.env.REACT_APP_BE+'/forgotPassword', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify( { "username": inputs.username } )
        })

        const fbData = await fbRes.json();

        if (fbData.status === "success") {
            const getEmail = () => {                
                if (fbData.email !== "") {
                    return fbData.email;
                } else if (fbData.username.indexOf("@") > 0 && fbData.username.indexOf(".") > 0) {
                    return fbData.username;
                } else {
                    return "none";
                }
            }
            const email = getEmail();

            if (email === "none") {
                setSuccess("");
                setError("You don't seem to have an email address on file, please contact support to have your password reset manually.");
            } else {
                setError("");
                setSuccess("An email has been sent to the email address in your account record.");
            }
            
        } else {
            setError("There was an issue, we either couldn't find your account, or you don't have an email address on file with us.");
        }

    }

    return (
        <>
            <ScrollToTop/>
            <img className="logo" src={logo} alt={logoAlt} />
            <form onSubmit={handleSubmit} className='loginForm'>
                <h1 className='login'>Forgot Password</h1>
                <h5 className="instruction">Please enter your email address, we'll email you a link that you can use to reset your password.</h5>
                <label htmlFor="username">Email</label>
                <input type="text" 
                    value={inputs.username || ""} 
                    maxLength="200" 
                    name="username" 
                    placeholder="youremail@example.com" 
                    onChange={handleChange}
                    autoFocus
                    disabled={ (successMessage==="") ? false : true }></input>
                <a className="loginlink" href="/login" alt="click here if you know your password and would like to log in">Back to Login</a>
                <input type="submit" value="Reset Password" name="dologin" disabled={ (successMessage==="") ? false : true }></input>
                <ErrorMessage content={errorMsg} />
                <SuccessMessage content={successMessage} />
                <p>Don't have an account? <a href="signup">Sign Up</a></p>
            </form>
            <div className="quotePanel">
                <img className="profilepic" src={profiles[thisprofileN].img} alt={profiles[thisprofileN].name} />
                <div className="quote">
                    “{profiles[thisprofileN].tag}”
                </div>
                <div className="byline">
                    {profiles[thisprofileN].name}
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
  