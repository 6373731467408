import React, { useState } from 'react';
import {useDropzone} from 'react-dropzone';
import downloadIcon from '../css/icons/icon-upload.png';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import dropzoneStyle from '../xcl_modules/dropzoneStyle';
import Swal from 'sweetalert2';

const downloadIconAlt = "upload file icon";

function DropzoneLogo(props) {

  const [uploadedImageSrc, setUploadedImageSrc] = useState( props.brand_logo );

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    uploadMulitple: false,
    maxFiles: 1,
    name: "logo_uploaded",
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
    
      const acceptedFileTypes = ['image/png','image/jpeg','image/jpg'];
          
      if (fileRejections.length>0) {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="correctitoast"></div>',
          title: "Please limit upload to a single file.",
          showConfirmButton: false,
          timer: 2000,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastWarningOn'
            },
          hideClass: {
              popup: 'toastWarningOff'
          }
        });
      } else {
        
        acceptedFiles.forEach((file) => {

          const reader = new FileReader();
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {

            // test the size, file.size is in KB (divide the props.storageAvailable by 1024)
            const storageAvail = (props.storageAvailable);

            if (file.size > storageAvail) {
              setUploadedImageSrc("");
              Swal.fire({
                position: "top",
                iconHtml:  '<div class="correctitoast"></div>',
                title: "Please select a different file, you don't have enough remaining storage for that one.",
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastWarningOn'
                  },
                hideClass: {
                    popup: 'toastWarningOff'
                }
              });
      
            } else {
              // Do whatever you want with the file contents
              if (acceptedFileTypes.indexOf(file.type) > -1) {
                // do a thing with a thing or two
                
                const userData = getUserData();
                const tokenData = getTokenData();
    
                // create a sudo form
                const formData = new FormData();
                formData.append("accountID", userData.accountID);
                formData.append("profileID", (props.profileID || "system"));
                formData.append("ftu", file);
    
                fetch(process.env.REACT_APP_BE+'/uploadlogo', {
                  method: 'POST',
                  headers: { Authorization: `Bearer ${tokenData.accessToken}`},
                  body: formData
                }).then((response) => { 
                  return response.json().then((data) => {
                    setUploadedImageSrc(data.pathToFile);
                    props.onChange('brand_logo', data.pathToFile);
                    return data;
                  }).catch((err) => {
                    console.log(err);
                  }) 
                });
    
              } else {
                setUploadedImageSrc("");
                Swal.fire({
                  position: "top",
                  iconHtml:  '<div class="correctitoast"></div>',
                  title: "Please limit uploads to PNG or JPEG (JPG) image formats.",
                  showConfirmButton: false,
                  timer: 2000,
                  toast: true,
                  timerProgressBar: true,
                  showClass: {
                      popup: 'toastWarningOn'
                    },
                  hideClass: {
                      popup: 'toastWarningOff'
                  }
                });
  
              }
            }
          }
          reader.readAsArrayBuffer(file);
        })
      }
    }
  });

  const dzButton = {
      color: "#513AD1",
      backgroundColor: "transparent",
      padding: "0px",
      textDecorationLine: "underline",
      border: "none",
      outline: "none"
  };

  const dlIcon = {
      display: "inline-block",
      margin: "20px auto 0px auto"
  }

  const doChangeImage = () => {
    setUploadedImageSrc("");
    props.onChange('brand_logo', " "); // needs to be a space bro, or the component will unload (adminsettings.js rule)
  }

  /*const doDeleteImage = () => {
    // for now we'll lose this
    // HTML <input type="button" className="deleteImageButton" href='#' onClick={doDeleteImage} value="Delete Image"></input>
    setUploadedImageSrc("");
  }*/

  return (
    <div className="container">
      <style>{dropzoneStyle}</style>
      <label htmlFor='logo_uploaded'>Upload a Logo or Image</label>
      <h5>We recommend using a logo that is a PNG with a transparent background for the best results.</h5>
      <p>
      File types: PNG or JPG<br/>
      Recommended Size: 376 x120px<br/>
      Storage Available: {Math.round(props.storageAvailable/1048576)} MB
      </p>
      {
        (uploadedImageSrc) ? (
          <>
            <div className="dropzone" style={{ background: `URL('${uploadedImageSrc}')`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center center" }} />
            <input type="button" className="changeImageButton" href='#' onClick={doChangeImage} value="Change Image"></input>
          </>
        ) : (
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <img src={downloadIcon} alt={downloadIconAlt} style={dlIcon} /><br/>
            Drag 'n' drop file here<br/>
            or<br/>
            <button type="button" style={dzButton} onClick={open}>
              Choose file
            </button>
          </div>
      )
    }
      
    </div>
  );
}

export default DropzoneLogo;