import ScrollToTop from '../components/ScrollToTop';
import linksStyle from '../xcl_modules/linksStyle';
import { List } from '../components/ListLinks';
import validate from '../xcl_modules/validate';
import chainlink from '../css/icons/icon-chain.png';
import chainlinkButtonGraphic from '../css/icons/icon-chain-btn.png';
import { useState, useEffect  } from 'react';
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import getTokenData from '../xcl_modules/tokenData';
import Swal from 'sweetalert2';

const chainlinkAlt = "Links in a chain";

const Links = () => {

  //const [changed, setChanged] = useState(false);
  const [profiles, setProfiles] = useState( 0 );
  const [cap, setCap] = useState( 1 );
  const [len, setLen] = useState( 0 );

  const [navOpen] = useOutletContext();

  const location = useNavigate();


  useEffect(() => {
    validate();

    const getData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BE+'/linkprofiles', {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 
                Authorization: `Bearer ${getTokenData().accessToken}`}
        });
        const result = await response.json();
        if (result.code === 202) {
            // there are no profiles :(
            result.list = [ { ID:0, listEmpty: "message" } ];
        } else {
          setProfiles(result.list.length);
        }       
        
      } catch {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "System Error :O  Please Login again.",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastCongratsOn'
          },
          hideClass: {
              popup: 'toastCongratsOff'
          }
        });
      }
    }
    getData();
  }, []);

  const doSelectRow = (rowID) => {
    localStorage.setItem("editLinkID", rowID);
    location("/linkname");
  } 

  const doSetCapAndLen = (thisCap, thisLen) => {
    setCap(thisCap);
    setLen(thisLen);
  }

  /*const dataChanged = () => {

    setChanged(!changed);
  }*/

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <style>{linksStyle}</style>
      <ScrollToTop/>
      <a name="contentstarts" className='contentStarts' href='#start'>
        <h1 name="start">
          <img src={chainlink} alt={chainlinkAlt} className='iconInline' />
          My Learning Links 
          <div title="Patent Statement" className='patentMessage listpage'>{process.env.REACT_APP_PATENT_MESSAGE}</div>
        </h1>
      </a>
      <Link to={(profiles>0 && cap>len ? '/linknew' : '')} tabIndex={1} className={'createNewBtn links '+(profiles>0 && cap>len ? '' : 'disabled')} style={{ backgroundImage: `url('${chainlinkButtonGraphic}')`}}>
      {(cap<=len) ? (
          <>
            You've reach the limit of Learning Links that you can create
          </>          
        ) : (
          <>
            Create a New Learning Link
          </>
        )}
      </Link>
      <h4>
        Create and manage your learning links here. Ensure you've created a branded landing page template first. You'll be applying it to your link. 
      </h4>
      <List 
        emptyheading="You haven't created a learning link yet!"
        createprofilelink="Create a brand template"
        emptyinstruction=" before you create your learning link. You'll need to apply branding to it during set-up!"
        linklabel="Create a Learning Link"
        profiles={profiles}
        linkto="/linknew"
        linktoAlt="/xcl/profiles"
        setCapAndLen={ doSetCapAndLen }
        doSelectRow={ doSelectRow }
        />
    </div>
    
  );
};

export default Links;