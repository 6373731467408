const cssClip = `
.createnew.header {
    display:block;
    background-color: #fff;
    margin: 0px;
    padding: 40px 0px 23px 0px;
    box-shadow: 0px 3px 12px 0px #B9B9B933;
}
.stepperheader {
    display:block;
    width: 85%;
    margin:0px auto;
}
.createnew > .stepperheader > a {
    text-decoration: none;
    color: #513AD1;
    font-size: 1.125rem;
}
.createnew > .stepperheader > h3 {
    font-size:1.5rem;
    line-height:100%;
    color: #0F172A;
    padding-bottom: 0px;
    margin: 13px 0px 0px 0px;
}
.createnew > .stepperheader > p {
    font-size: 1.125rem;
    color: #334155;
    line-height: 100%;
}
.steps {
    margin: 20px 0px 0px 0px;
    display:table;
}
.steps > hr {
    display: inline-block;
    height: 1px;
    width: 60px;
    vertical-align: middle;
    margin: 0px 15px 0px 15px;
}
.step {
    display: table-cell;
    text-align:left;
    vertical-align: middle;
}
.step > a {
    font-size: 1rem;
    line-height: 100%;
    font-weight: 700;
    text-decoration: none;
}
.indicator {
    width: 15px;
    height: 15px;
    padding: 5px;
    margin: -4px 8px 0px 0px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    line-height: 90%;
    display: inline-block;
    vertical-align: middle;
}
.step.active a {
    color: #00606B;
}
.step.links.active a {
    color: #41206C;
}
.step.active .indicator {
    background-color: #00606B;
    color: #ffffff;
}
.step.links.active .indicator {
    background-color: #41206C;
    color: #ffffff;
}
.step.prior a {
    color: #0F172A;
}
.step.prior .indicator {
    background-color: #0F172A;
    color: #ffffff;
}
.step.upcoming a {
    color: #64748B;
}
.step.upcoming .indicator {
    background-color: #94A3B8;
    color: #ffffff;
}
.end {
    height: 200px;
}
.formContainer {
    width: 850px;
    padding: 35px 54px;
    border-radius: 8px;
    border: 1px solid #CBD5E1;
    background-color: #FFFFFF;
    margin: 48px auto 24px auto;
}
.formContainer h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 100%;
    color: #0F172A;
}
.inlineLabel {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 100% !important;
    color: #0F172A !important;
    display: inline;
    margin: 0px 150px 0px 8px;
}
.formContainer p, .formContainer h5, .fileName {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #475569;
}
.formContainer label {
    font-size: 1rem;
    line-height: 100%;
    font-weight: 700;
    color: #0F172A;
    margin-bottom: 0px;
}
.formFooter {
    position: fixed;
    width: 100%;
    height: 97px;
    bottom: 0px;
    left: 0px;
    padding: 0px 0px;
    background-color:#ffffff;
}
.progressbarbackground {
    background-color: #DDDEE0;
    height: 8px;
}
.progressbarprogress {
    background-color: #0290A0;    
    height: 8px;
}
.progressbarprogress.links {
    background-color: #7D51B6;
}
.stepperFormControls {
    width: 85%;
    margin: 23px auto;
}
.stepperFormControls > input[type='submit'] {
    float:right;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    border-radius: 10px;
    background-color: #0F172A;
    color: #F8FAFC;
    border: 0px;
    padding: 12px 40px 12px 18px;
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) 50%;
    cursor: pointer;
}
.stepperFormControls > input[type='button'] {
    float:left;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #0F172A;
    border: 1px solid #0F172A;
    padding: 12px 18px 12px 40px;
    background-repeat: no-repeat;
    background-position: 15px 50%;
    cursor: pointer;
}
.fieldListHeadings > label {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 100%;
    display:inline-block;
}
.ordercol {
    width: 110px;
}
.namecol {
    width: 132px;
}
.labelcol {
    width: 365px;
}
.visiblecol {
    width: 113px;
}
.requiredcol {
    width: auto;
}
.fieldDroppable {
   list-style: none;
   counter-reset: item;
   width: 100%;
   padding: 0px;
   vertical-align: center;
}
.fieldDroppable li {
    border: 1px solid #333;
    border-radius: 6px;
    background-color:#fff;
    padding: 2px;
    margin: 16px 0px 0px 0px;
    height: 62px;
    counter-increment: item;
    display: block;
    vertical-align: middle;
}
.fieldDroppable li:before {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 100%;
    color: #030E31;
    padding-left: 10px;
    content: counter(item) ". ";
    width: 60px;
    display: inline-block;
    text-align: left;
    margin-top: 16px;
}
.fieldName {
    display:inline-block;
    width: 130px;
}
.fieldlabelInput {
    width: 260px !important;
    margin: 4px 0px 0px 0px !important;
}
.visSwitch {
    top: 7px;
    left: 70px;
}
.reqSwitch {
    top: 7px;
    left: 133px;
}
select {
    height: 52px;
    margin: 14px 0px 0px 0px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #94A3B8;
    font-size: 0.875rem;
    line-height: 100%;
    color: #0F172A;
    min-width: 420px;
    display: block;
}
select option {
    height:44px;
    background-color: #FFF;
    font-size: 0.875rem;
    line-height:100%;
    color:#0F172A;
    font-weight: 400;
}
select option:hover {
    background-color: #F9FFAA;
    font-weight: 700;
}
.hideme {
    display: none;
}
a.linkpublish {
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    border-radius: 10px;
    color: #F8FAFC;
    border: none;
    background-color: #41206C;
    padding: 12px 43px;
    cursor: pointer;
    text-decoration: none;
    display: block;
    margin: 25px auto 0px auto;
    height: 22px;
    width: 136px;
}
.moveHandles {
    width: 12px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 25px;
}
.moveDown, .moveUp {
    width: 12px;
}
.moveUp img {
    vertical-align: bottom;
}
.moveDown img {
    vertical-align: top;
}
`;

export default cssClip;