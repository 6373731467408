const cssClip = `
        .fileName {
            margin: 8px 0px 0px 0px;
        }

        .dropzone {
            width: 376px;
            height: 122px;
            text-align:center;
            border-radius: 10px;
            border: 1px dashed #555;
            background-color: #F1F5F9;
            overflow:hidden;
        }

        input[type="button"].changeImageButton, input[type="button"].deleteImageButton {
            border:none;
            background-color: transparent;
            outline: none;
            font-size: 0.875rem;
            font-weight: 700;
            text-decoration: underline;
            padding:0px;
            height:auto;
        }

        input[type="button"].changeImageButton {
            color: #513AD1;
            margin: 8px 0px 0px 0px;
        }

        input[type="button"].deleteImageButton {
            color: #B91C1C;
            margin: 8px 0px 0px 195px;
        }
        .fileIcon {
            
        }
        .fileNameBadge {
            width: 26px;
            height: 12px;
            font-size: 0.5rem;
            line-height: 160%;
            text-align: center;
            border-radius: 2px;
            background-color: #475569;
            color: #fff;
            display: block;
            position: relative;
            top: -29px;
            left: 165px;
            text-transform: uppercase;
        }
    `;

export default cssClip;