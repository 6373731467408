import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import createStyles from '../xcl_modules/createStyles';
import { Link, useNavigate } from "react-router-dom";
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import prevStepIcon from '../css/icons/icon-arrowleft.png';
import getTokenData from '../xcl_modules/tokenData';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import LivePreview from '../components/LivePreview';
import Swal from 'sweetalert2';

import iconMoveUp from '../css/icons/icon-field-drag-handle-up.png';
import iconMoveDown from '../css/icons/icon-field-drag-handle-down.png';

import Switch from "react-switch";

const startFieldsData = {
  "0": {
      "ID": 26,
      "parent": 132567,
      "fieldID": "firstname",
      "type": "text",
      "label": "First Name",
      "visible": 1,
      "required": 1,
      "ordinal": 0,
      "status": 1
  },
  "1": {
      "ID": 27,
      "parent": 132567,
      "fieldID": "lastname",
      "type": "text",
      "label": "Last Name",
      "visible": 1,
      "required": 1,
      "ordinal": 1,
      "status": 1
  },
  "2": {
      "ID": 28,
      "parent": 132567,
      "fieldID": "email",
      "type": "text",
      "label": "Email Address",
      "visible": 1,
      "required": 1,
      "ordinal": 2,
      "status": 1
  },
  "3": {
      "ID": 29,
      "parent": 132567,
      "fieldID": "userid",
      "type": "text",
      "label": "User ID",
      "visible": 1,
      "required": 0,
      "ordinal": 3,
      "status": 1
  }
};

const ProfileCustomize = () => {

  const [inputs, setInputs] = useState( {} );
  const [systemSettings, setSystemSettings] = useState( {} );
  const [profileFields, setFields] = useState( startFieldsData );
  const [lpMode, setLpMode] = useState( "web" );

  const navigate = useNavigate();

  useEffect(() => {
      validate();
      // get data
      const getData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BE+`/linkprofile/${localStorage.getItem("editProfileID")}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json', 
                    Authorization: `Bearer ${getTokenData().accessToken}`}
            });
            const result = await response.json();

            if (result.status === "success") {
                const initialInputs = {};
                const systemSettins = {};
                Object.entries(result.data[0]).forEach(([key, val]) => {
                    initialInputs[key] = val;
                });
                Object.entries(result.fields).forEach(([key, val]) => {
                    setFields(values => ({...values, [key]: val }));
                });
                result.system_settings.forEach((setting) => {
                    systemSettins[setting.appid] = setting.value;
                    setSystemSettings(values => ({...values, [setting.appid]: setting.value }));
                });
                // sort if needed
                Object.entries(systemSettins).forEach(([key, val]) => {
                    if (typeof(initialInputs[key])!=="undefined") {
                        if (initialInputs[key]==="") {
                            initialInputs[key] = val;
                        }
                    }
                });
                //console.log(initialInputs);
                Object.entries(initialInputs).forEach(([key, val]) => {
                    setInputs(values => ({...values, [key]: val }));
                });
            }

            //console.log(inputs);
        } catch {
            Swal.fire({
              position: "top",
              iconHtml:  '<div class="positoast"></div>',
              title: "System Error while saving this Brand Template. Please refresh and try again.",
              showConfirmButton: false,
              timer: 2000,
              toast: true,
              timerProgressBar: true,
              showClass: {
                  popup: 'toastCongratsOn'
              },
              hideClass: {
                  popup: 'toastCongratsOff'
              }
            });
        }
    }
    getData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const saveData = async () => {

      Object.entries(profileFields).forEach(([key, val]) => {
        //console.log(val.ID);
        try {
          fetch(process.env.REACT_APP_BE+'/fields', {
              method: 'POST',
              headers: {'Content-Type': 'application/json', 
                  Authorization: `Bearer ${getTokenData().accessToken}`},
              body: JSON.stringify( { ID: val.ID, label: val.label.toString(), visible: val.visible.toString(), required: val.required.toString(), ordinal: key.toString() } )
          });
        } catch {
            Swal.fire({
              position: "top",
              iconHtml:  '<div class="positoast"></div>',
              title: "System Error while saving the fields. Please refresh and try again.",
              showConfirmButton: false,
              timer: 2000,
              toast: true,
              timerProgressBar: true,
              showClass: {
                  popup: 'toastCongratsOn'
              },
              hideClass: {
                  popup: 'toastCongratsOff'
              }
            });
        }
      });

      try {
        const response = await fetch(process.env.REACT_APP_BE+'/linkprofile', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 
                Authorization: `Bearer ${getTokenData().accessToken}`},
            body: JSON.stringify( { ID: localStorage.getItem("editProfileID"), isRandom: inputs.isRandom.toString() } )
        });
        const result = await response.json();

        if (result.status === "success") {
          Swal.fire({
            position: "top",
            iconHtml:  '<div class="positoast"></div>',
            title: "Your edits have been saved!",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            timerProgressBar: true,
            showClass: {
                popup: 'toastCongratsOn'
              },
            hideClass: {
                popup: 'toastCongratsOff'
            }
          });
          navigate("/xcl/profiles");
        }
      } catch {
          console.log("error with data - log in again");
          Swal.fire({
            position: "top",
            iconHtml:  '<div class="positoast"></div>',
            title: "System Error :O  Please Login again.",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            timerProgressBar: true,
            showClass: {
                popup: 'toastCongratsOn'
            },
            hideClass: {
                popup: 'toastCongratsOff'
            }
          });
      }

    }
    saveData();
  }

  const handlePrevNavigation = (event) => {
      event.preventDefault();
      navigate("/profilebranding");
  }

  const changeModeToMobile = () => { 
      setLpMode( "mobile" );
  }

  const changeModeToWeb = () => { 
      setLpMode( "web" );
  }
  const fieldMoved = (res) => {

    const { destination, source, reason } = res;

    if (reason!=="DROP") {
      // didn't cancel or error
      return;
    }

    if (destination.droppableId===source.droppableId && destination.index===source.index) {
      // didn't move it
      return;
    } 

    if (destination.droppableId!=="fieldList") {
      // didn't drop outside the list
      return;
    }
      
    //const sourceData = profileFields[source.index];
    //const destData = profileFields[destination.index];
    const newPF = {};
    let i = 0;
    let n = 0;
    // add item in correct destination
    newPF[destination.index] = profileFields[source.index];
    // remove it
    delete(profileFields[source.index]);
    
    // reflow the others
    Object.entries(profileFields).forEach(([key, val]) => {
      if (n===destination.index) {
        //console.log("dest found = " + destination.index);
        i++;
      }
      //console.log(i);
      newPF[i] = val;
      i++;
      n++;
    });

    setFields({...newPF});

  }

  const handleChangeInt = (event) => {
    const name = event.target.name;
    const value = parseInt(event.target.value);

    setInputs(values => ({...values, [name]: value}));

  }
  
  const handleProfileChange = (event) => {

    let name = event.target.name.split("_");
    const ordinal = name[1];
    const key = "label";
    const value = event.target.value;
    profileFields[ordinal][key] = value;
    
    setFields({...profileFields});

  };

  const handleVisSwitch = (checked, event, id) => {
    
    let numberVisible = 0;
    
    Object.entries(profileFields).forEach((profileField, pos) => {
      if (profileFields[pos]){
        if (profileFields[pos].visible===1) {
          numberVisible ++;
        }
      }
    });

    if ((numberVisible > 1 && !checked) || checked) {

      const name = id.split("_");
      const ordinal = name[1];
      const key = "visible";
      profileFields[ordinal][key] = (checked ? 1 : 0);
  
      setFields({...profileFields});
  
      // turn off the required flag
      const reqKey = "required";
      if (profileFields[ordinal][reqKey]!==0) {
        profileFields[ordinal][reqKey] = (0);
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="correctitoast"></div>',
          title: "Required switch has been turned off for you.",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastWarningOn'
            },
          hideClass: {
              popup: 'toastWarningOff'
          }
        });
      }
  
    } else {
      Swal.fire({
        position: "top",
        iconHtml:  '<div class="correctitoast"></div>',
        title: "You need at least one visible field, OR, you can use the Random ID functionality.",
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        timerProgressBar: true,
        showClass: {
            popup: 'toastWarningOn'
          },
        hideClass: {
            popup: 'toastWarningOff'
        }
      });

    }
    
  }

  const handleReqSwitch = (checked, event, id) => {

    const name = id.split("_");
    const ordinal = name[1];
    const key = "required";
    profileFields[ordinal][key] = (checked ? 1 : 0);

    if (profileFields[ordinal]["visible"]===1) {
      setFields({...profileFields});
    }
    
  }

  const progress = "100%";
  
  const moveDown = (res) => {
    
    const idparts = res.target.id.split("_");

    const fromIndex = parseInt(idparts[1]);
    const toIndex = fromIndex + 1;

    moveField(fromIndex, toIndex);

  }

  const moveUp = (res) => {
    
    const idparts = res.target.id.split("_");

    const fromIndex = parseInt(idparts[1]);
    const toIndex = fromIndex - 1;
    
    moveField(fromIndex, toIndex);

  }

  const moveField = (fromIndex, toIndex) => {

    const from = fromIndex;
    const to = toIndex;
    
    const newPF = {};
    let i = 0;
    let n = 0;
    // add item in correct destination
    newPF[to] = profileFields[from];
    // remove it
    delete(profileFields[from]);
    
    // reflow the others
    Object.entries(profileFields).forEach(([key, val]) => {
      if (n===to) {
        //console.log("dest found = " + destination.index);
        i++;
      }
      //console.log(i);
      newPF[i] = val;
      i++;
      n++;
    });

    setFields({...newPF});

  }
  
  return (
      <>
      <Header/>
      <ScrollToTop/>
      <style>{createStyles}</style>
      <div className='createnew header'>
          <div className='stepperheader'>
          <Link to="/xcl/profiles">&lt; Back to Dashboard</Link>
              <h3>
              Branded Landing Page Template 
              </h3>
              <p>
              You'll be able to apply this template to your learning link landing pages 
              </p>
              <div className='steps'>
              <div className='step prior'>
                  <Link to="/profilename">
                  <div className='indicator' title="step 1">&#10003;</div>
                  Template Name
                  </Link>
              </div>
              <hr/>
              <div className='step prior'>
                  <Link to="/profilelrs">
                  <div className='indicator' title="step 2">&#10003;</div>
                  LRS Details
                  </Link>
              </div>
              <hr/>
              <div className='step prior'>
                  <Link to="/profilebranding">
                  <div className='indicator' title="step 3">3</div>
                  Logo & Background Color
                  </Link>
              </div>
              <hr/>
              <div className='step active'>
                  <Link>
                  <div className='indicator' title="step 4">4</div>
                  Customize Fields
                  </Link>
              </div>
              </div>
          </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='formContainer'>
          <a name="contentstarts" className='contentStarts' href='#start'>
            <h4 name="start">Landing Page Preview</h4>
          </a>
            <div className='lpControls'>
                <input type="button" className={(lpMode === "mobile") ? "active" : ""} value="Mobile" onClick={changeModeToMobile}/>
                <input type="button" className={(lpMode === "web") ? "active" : ""} value="Web" onClick={changeModeToWeb}/>
            </div>
            <LivePreview
              lpMode={lpMode}
              profileFields={profileFields}
              inputs={inputs}
              systemSettings={systemSettings}
            />
        </div>

        <div className='formContainer edit'>
          <h4>Customize your user input fields</h4>
          <p>Select if you would like your users to enter information before accessing your content or if you would like an 
            autogenerated anonymous random ID. They will not enter any information with a random ID.</p>
          
          <input type='radio' id='customform' name='isRandom' value='0' onChange={handleChangeInt} checked={(inputs.isRandom===1 ? false : true )}></input>
          <label htmlFor="customform" className='inlineLabel'>Customizable Form</label>
          <input type='radio' id='random' name='isRandom' value='1' onChange={handleChangeInt} checked={(inputs.isRandom===1 ? true : false )}></input>
          <label htmlFor="random" className='inlineLabel'>Random ID <span className="icon-inlinesupport"></span></label>
          

          <div className='fieldsContainer' style={ inputs.isRandom===1 ? { display: "none" } : { display: "block"} }>

            <label>Customize your form fields</label>
            <h5>Customize the field labels, and toggle visibility or required fields in the table below. Select an item and use your up 
              and down arrow keys, or drag and drop to reposition it in the field order.</h5>
            
            <div title="fields list headings" className='fieldListHeadings'>
              <label className='ordercol'>ORDER</label>
              <label className='namecol'>FIELD NAME</label>
              <label className='labelcol'>LABEL</label>
              <label className='visiblecol'>VISIBLE</label>
              <label className='requiredcol'>REQUIRED</label>
            </div>

            <DragDropContext onDragEnd={fieldMoved} >
              <Droppable droppableId='fieldList'>
                {(provided) => (
                <ol className='fieldDroppable' ref={provided.innerRef} {...provided.droppableProps}>
                  <Draggable draggableId='fnfield' index={0}>
                    {(provided) => (
                      <li
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className='field'>
                          <div className='moveHandles'>
                            <Link className='moveUp' id="moveuplink_0" disabled >
                              <img id="moveuparrow_0" src={iconMoveUp} alt="This field is at the top, cannot be moved up" style={{ opacity: "0.5" }} />
                            </Link>
                            <Link className='moveDown' id="movedwnlink_0" onClick={moveDown}>
                              <img id="movedwnarrow_0" src={iconMoveDown} alt="Move this field down 1 in the order" />
                            </Link>
                          </div>
                          <div className='fieldName'>{profileFields[0].fieldname}</div>
                          <input type="text"
                            maxLength="60"
                            className='fieldlabelInput'
                            value={profileFields[0].label || ""}
                            name='profilelabel_0'
                            onChange={handleProfileChange}
                            ></input>
                            <Switch id="profilevis_0" onChange={handleVisSwitch} checked={(profileFields[0].visible ? true : false)} offColor='#858993' onColor='#00606B' aria-label='Visibility for this field' className='visSwitch'/>
                            <Switch id="profilereq_0" onChange={handleReqSwitch} checked={(profileFields[0].required ? true : false)} disabled={(profileFields[0].visible===0)} offColor='#858993' onColor='#00606B' aria-label='Is this field required' className='reqSwitch'/>
                        </li>
                    )}
                  </Draggable>
                  <Draggable draggableId='lnfield' index={1}>
                    {(provided) => (
                      <li
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className='field'>
                          <div className='moveHandles'>
                            <Link className='moveUp' id="moveuplink_1" onClick={moveUp}>
                              <img id="moveuparrow_1" src={iconMoveUp} alt="Move this field up 1 in the order" />
                            </Link>
                            <Link className='moveDown' id="movedwnlink_1" onClick={moveDown}>
                              <img id="movedwnarrow_1" src={iconMoveDown} alt="Move this field down 1 in the order" />
                            </Link>
                          </div>
                          <div className='fieldName'>{profileFields[1].fieldname}</div>
                          <input type="text"
                            maxLength="60"
                            className='fieldlabelInput'
                            value={profileFields[1].label || ""}
                            name='profilelabel_1'
                            onChange={handleProfileChange}
                            ></input>
                            <Switch id="profilevis_1" onChange={handleVisSwitch} checked={(profileFields[1].visible ? true : false)} offColor='#858993' onColor='#00606B' aria-label='Visibility for this field' className='visSwitch'/>
                            <Switch id="profilereq_1" onChange={handleReqSwitch} checked={(profileFields[1].required ? true : false)} disabled={(profileFields[1].visible===0)} offColor='#858993' onColor='#00606B' aria-label='Is this field required' className='reqSwitch'/>
                        </li>
                    )}
                  </Draggable>
                  <Draggable draggableId='emfield' index={2}>
                    {(provided) => (
                      <li
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className='field'>
                          <div className='moveHandles'>
                            <Link className='moveUp' id="moveuplink_2" onClick={moveUp}>
                              <img id="moveuparrow_2" src={iconMoveUp} alt="Move this field up 1 in the order" />
                            </Link>
                            <Link className='moveDown' id="movedwnlink_2" onClick={moveDown}>
                              <img id="movedwnarrow_2" src={iconMoveDown} alt="Move this field down 1 in the order" />
                            </Link>
                          </div>
                          <div className='fieldName'>{profileFields[2].fieldname}</div>
                          <input type="text"
                            maxLength="60"
                            className='fieldlabelInput'
                            value={profileFields[2].label || ""}
                            name='profilelabel_2'
                            onChange={handleProfileChange}
                            ></input>
                            <Switch id="profilevis_2" onChange={handleVisSwitch} checked={(profileFields[2].visible ? true : false)} offColor='#858993' onColor='#00606B' aria-label='Visibility for this field' className='visSwitch'/>
                            <Switch id="profilereq_2" onChange={handleReqSwitch} checked={(profileFields[2].required ? true : false)} disabled={(profileFields[2].visible===0)} offColor='#858993' onColor='#00606B' aria-label='Is this field required' className='reqSwitch'/>
                        </li>
                    )}
                  </Draggable>
                  <Draggable draggableId='uifield' index={3}>
                    {(provided) => (
                      <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className='field'>
                        <div className='moveHandles'>
                          <Link className='moveUp' id="moveuplink_3" onClick={moveUp}>
                            <img id="moveuparrow_3" src={iconMoveUp} alt="Move this field up 1 in the order" />
                          </Link>
                          <Link className='moveDown' id="movedwnlink_3" disabled>
                            <img id="movedwnarrow_3" src={iconMoveDown} alt="This field is at the bottom, cannot be moved down" style={{ opacity: "0.5" }} />
                          </Link>
                        </div>
                        <div className='fieldName'>{profileFields[3].fieldname}</div>
                        <input type="text"
                            maxLength="60"
                            className='fieldlabelInput'
                            value={profileFields[3].label || ""}
                            name='profilelabel_3'
                            onChange={handleProfileChange}
                            ></input>
                            <Switch id="profilevis_3" onChange={handleVisSwitch} checked={(profileFields[3].visible ? true : false)} offColor='#858993' onColor='#00606B' aria-label='Visibility for this field' className='visSwitch'/>
                            <Switch id="profilereq_3" onChange={handleReqSwitch} checked={(profileFields[3].required ? true : false)} disabled={(profileFields[3].visible===0)} offColor='#858993' onColor='#00606B' aria-label='Is this field required' className='reqSwitch'/>
                      </li>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </ol>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className='formFooter'>
            <div className='progressbarbackground'>
                <div className='progressbarprogress' style={{ width: progress }}></div>
            </div>
            <div className='stepperFormControls'>
                <input type="button" name='backone' value="Previous: Branding" style={{ backgroundImage: `url('${prevStepIcon}')`}} onClick={handlePrevNavigation}></input>
                <input type="submit" name='subitBtn' value="Next: Finish" style={{ backgroundImage: `url('${saveStepIcon}')`}}></input>
            </div>
          </div>
        </div>
      </form>
      <div className='end'> </div>
    </>
  );
};

export default ProfileCustomize;