import logo from '../img/xcl-logo.png';
import { useState } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import { ErrorMessage, SuccessMessage } from '../components/InlineMessages';
import Profiles from '../components/Loginprofiles';

const logoAlt = "Build Capable XCL Content Links Logo";

const rand = Math.random() * Profiles.length;
let thisprofileN = Math.floor(rand);

const Login = () => {
    const [inputs, setInputs] = useState({});
    const [errorMsg, setError] = useState("");
    const [successMessage, setSuccess] = useState("");

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }
    
    const handleChangeCB = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        if (value === true) {
            window.localStorage.setItem("rememberme", "true");
        } else {
            window.localStorage.removeItem("rememberme");
        }
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // prepare the vars
        localStorage.setItem("editLinkID","");
        localStorage.setItem("editProfileID","");
        localStorage.setItem("xclprofiles","");
        localStorage.setItem("xcllinks","");

        // do the login thing, get the data and store it to localstorage for use accross the site.
        const loginRes = await fetch(process.env.REACT_APP_BE+'/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: '{ "name": "'+inputs.username+'", "password": "'+inputs.password+'" }'
        })

        const loginData = await loginRes.json();

        // handle the rememberme
        if (inputs.rememberme) {
            // store the username and password
            window.localStorage.setItem("rememberme", "true");
            window.localStorage.setItem("xclloginusername", inputs.username);
            window.localStorage.setItem("xclloginpassword", inputs.password);
        } else {
            window.localStorage.setItem("rememberme", "false");
            window.localStorage.removeItem("xclloginusername");
            window.localStorage.removeItem("xclloginpassword");
        }
        // store user and account data to the master
        if (loginData.status === "success") {
            window.localStorage.setItem("xcldata", JSON.stringify(loginData.data));
            window.localStorage.setItem("xcltokens", JSON.stringify(loginData.tokens));
            setError("");
            //setSuccess("Success!");
            document.location = "/xcl/";
        } else {
            // error
            window.localStorage.setItem("xcldata", "{}");
            window.localStorage.setItem("xcltokens", "{}");
            // show the error
            setError("Your username and/or password was incorrect, please review and try again.");
            setSuccess("");
        }

    }

    const rememberme = window.localStorage.getItem("rememberme");
    if (rememberme === "true" && typeof(inputs.rememberme)==="undefined") {
        typeof(inputs.username)==="undefined" ? inputs.username = window.localStorage.getItem("xclloginusername") : void(0);
        typeof(inputs.password)==="undefined" ? inputs.password = window.localStorage.getItem("xclloginpassword") : void(0);
        inputs.rememberme = true;
    }

    return (
        <>
            <ScrollToTop/>
            <img className="logo" src={logo} alt={logoAlt}/>
            <div title="Patent Statement" className='patentMessage login'>{process.env.REACT_APP_PATENT_MESSAGE}</div>
            <form onSubmit={handleSubmit} className='loginForm'>
                <h1 className='login'>Log In</h1>
                <h5 className="instruction">Please enter your login details.</h5>
                <label htmlFor="username">Email</label>
                <input type="text" 
                    value={inputs.username || ""} 
                    maxLength="200" 
                    name="username" 
                    placeholder="youremail@example.com" 
                    onChange={handleChange}
                    autoFocus></input>
                <label htmlFor="password">Password</label>
                <input type="password"
                    value={inputs.password || ""} 
                    maxLength="70"
                    name="password" 
                    onChange={handleChange}
                    placeholder="Enter Password"></input>
                <input type="checkbox"
                    name="rememberme"
                    checked={inputs.rememberme || false}
                    onChange={handleChangeCB}></input>
                <label htmlFor="rememberme">Remember Me</label>
                <a className="forgotpass" href="/forgotpassword" alt="click here if you have forgotten your password">Forgot Password?</a>
                <input type="submit" value="Log in" name="dologin"></input>
                <ErrorMessage content={errorMsg} />
                <SuccessMessage content={successMessage} />
                <p>Don't have an account? <a href={process.env.REACT_APP_XCLSIGNUP}>Sign Up</a></p>
            </form>
            <div className="quotePanel">
                <img className="profilepic" src={Profiles[thisprofileN].img} alt={Profiles[thisprofileN].name} />
                <div className="quote">
                    “{Profiles[thisprofileN].tag}”
                </div>
                <div className="byline">
                    {Profiles[thisprofileN].name}
                </div>
            </div>
        </>
    );
};

export default Login;
  