const userData = () => {
    let thisData = {};
    const loc = document.location;
    const ls = localStorage;

    if (ls.getItem("xcldata")!=null) {
        // fill the thing and JSON it
        thisData = JSON.parse( ls.getItem("xcldata") );
        if (ls.userID === null) {
            if (loc.href.indexOf("/login") < 0) {
                loc.href = "/login";
            }
        }
    } else {
        // go to login
        if (loc.href.indexOf("/login") < 0) {
            loc.href = "/login";
        }
    }

    return thisData;
};
  
export default userData;