const FAQData = [
    {
        title: "What is Build Capable XCL?",
        content: [
            `XCL is an xAPI-enabled content launcher. It is a tool that you can use to generate a direct link to any type of content, including eLearning, video, PDFs, and websites. When a person uses an XCL Learning Link to access your content, you'll collect data on the person and their interactions with that content. You can view this data in real time and create dashboards and reports for insight into the information that is most important to you.`,
        ]
    },
    {
        title: "What is a Learning Link?",
        content: [
            `A Learning Link is the direct link to content that you create using XCL. Most XCL users link to elearning, videos, PDFs, and websites, but we encourage you to use XCL to create a link to any type of online content for easy distribution.`,
            `When a person uses a Learning Link to access your content, data will be sent to a Learning Record Store (LRS) of your choice (or simply use the LRS included with your XCL subscription). This data will include information about the person and their interactions with the content.`,
        ]
    },
    {
        title: "What is a Brand Template?",
        content: [
            `The Brand Template produces a simple, customizable landing page for your content based on your selections. This landing page allows you to collect information about the person accessing your content. You decide what combination of information you want to collect: name, email, an identification number… or make it totally anonymous!`,
        ]
    },
    {
        title: "What is xAPI?",
        content: [
            `The Experience API, or xAPI, is a standardized way to collect individualized data about learning experiences. xAPI allows you to track activities across systems, events, and interactions in a common data format.`,
            `XCL is built on the xAPI standard, which means that you can collect rich, participant-level data when you use XCL Learning Links.`,
        ]
    },
    {
        title: "Do I need to learn xAPI to use XCL?",
        content: [
            `Absolutely not. Even though we would love for you to learn more about what xAPI can do for you, that doesn't mean you can't start using XCL and collecting data. You don't even have to program your content to send xAPI data. Our patented translator will convert actions taken in your content to an xAPI format to send to the Learning Record Store (LRS) - and you don't need to write a single line of code. However, taking the time to learn a thing or two about xAPI will allow you to collect better data specific to your unique needs and goals.`,
            `To learn more about how to get the most out of xAPI, visit <a href="https://learnxapi.com/" target="_blank" ref="noreferrer">Learn xAPI</a> and sign up for xAPI Basics.`,
        ]
    },
    {
        title: "What is a Learning Record Store (LRS)?",
        content: [
            `An LRS is a database that stores learning experience data using xAPI. You can view real-time data in the LRS as individuals explore your content! Your XCL subscription comes with an LRS, but you can also choose to connect to your own. The data from your LRS can be analyzed in dashboards and reports to easily access the information you need most, such as identifying who has accessed your content, completion records, and so much more!`,
        ]
    },
    {
        title: "Can I use my own Learning Record Store (LRS)?",
        content: [
            `Yes! You can connect to your own LRS when setting up a Brand Template. All you need is the LRS Endpoint, User Name (key), and Password (secret). If you use more than one LRS, set up a Brand Template for each LRS.`,
            `Looking for a different LRS? Visit <a href="https://adopters.adlnet.gov/products/all/0" target="_blank" rel="noreferrer">Advanced Distributed Learning's list of conformant LRSs</a>.`,
        ]
    },
    {
        title: "I don't want to load my content to XCL. How do I link to content on my own server?",
        content: [
            `When creating a Learning Link, select “URL” during Step 2: Learning Link Content and enter a URL to the launch file for your content. For example, let's say that you have an elearning course that is hosted on AWS S3. In this case, you'll enter the URL to the index file in the elearning package.`,
            `If you'd like to request support services for this process, contact us.`,
        ]
    },
    {
        title: "I have content that is programmed to send xAPI data. Will XCL send my custom statements?",
        content: [
            `Yes! If you already have xAPI-enabled content, XCL automates this process. Distribute your content with an XCL Learning Link to seamlessly collect actor information and direct the xAPI data to your LRS. It's so easy, you'll want to take us out to a nice dinner with all the time and money you save.`,
        ]
    },
    {
        title: "I have an elearning course that is published for SCORM 2004. Can I use XCL?",
        content: [
            `Yes! Simply upload your SCORM package during Step 2: Learning Link Content and our patented translator will convert the SCORM data to xAPI. You can access your data in the Learning Record Store (LRS). XCL storage rates and LRS data limits may apply.`,
        ]
    },
    {
        title: "Does XCL support videos?",
        content: [
            `Yes! You can either load your video to XCL or link to the video during Step 2: Learning Link Content. Our patented translator will convert actions performed in the video to xAPI. You will be able to access this data in the Learning Record Store (LRS). XCL storage rates and LRS data limits may apply.`,
        ]
    },
    {
        title: "Does XCL support PDFs?",
        content: [
            `Yes! You can either load your PDF to XCL or link to the PDF during Step 2: Learning Link Content. Our patented translator will convert actions performed in the PDF to xAPI. You will be able to access this data in the Learning Record Store (LRS). XCL storage rates and LRS data limits may apply.`,
        ]
    },
    {
        title: "What if I need to update the content in one of my Learning Links? Do I have to create a new Learning Link?",
        content: [
            `Nope, that's not necessary. Simply edit the existing Learning Link to upload the new content or link to it and save. The Learning Link remains the same!`,
        ]
    },
    {
        title: "Can I use an XCL Learning Link in my Learning Management System (LMS), Learning Experience Platform (LXP), Content Management System (CMS), or website?",
        content: [
            `Yes! As long as your platform allows you to add hyperlinks to external content, you should be able to use Learning Links. Try it!`,
        ]
    },
    {
        title: "Can I delete my account?",
        content: [
            `You can delete your account at any time. This will permanently delete all account information, including your Brand Templates and Learning Links at the end of your current subscription period. But please be sure you want to do this: once you delete your account, there is no going back!`,
            `To delete your account, go to “My Account” and select the “Delete My Account” option. We do not provide prorated refunds, so your account will remain active until the end of your current subscription period.`,
        ]
    },
];

export default FAQData;