import React, { useState } from "react";
import FAQData from './FAQData';
import iconChevDown from '../css/icons/icon-chev-down.png';
import iconChevUp from '../css/icons/icon-chev-up.png';


const FAQTile = (props) => {
    const [open, setOpen] = useState( false );

    const doOnClick = () => {
        setOpen(!open);
    }

    return (
        <li style={ ((open) ? { cursor:"pointer",paddingBottom: "0px",backgroundImage: `url(${iconChevUp})` } : { cursor:"pointer",paddingBottom: "12px",backgroundImage: `url(${iconChevDown})` } )} onClick={doOnClick} role="button" aria-label="click to show answer">
            <h5>{props.title}</h5>
            <p style={ ((open) ? { display:"block" } : { display: "none" } )}>
            {props.content.map((paragraph, i) => (
                <span key={"faq_"+i}>
                    <span dangerouslySetInnerHTML={{ __html: paragraph }}/>
                    <br/>{(props.content.length>1 && i<props.content.length-1) ? <br/> : <></> }
                </span>
            ))}
            </p>
      </li>
    );
}

const FAQTiles = () => {
    
    const tiles = FAQData;

    return (
        <ol className="faqList">
            {tiles.map((tile, i) => (
                <FAQTile key={i} title={tile.title} content={tile.content}/>
            ))}
        </ol>
    )

}

export default FAQTiles;